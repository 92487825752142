import { FieldLabel } from "@measured/puck";
import { CustomInputCell } from "./CustomInputCell";
import { ICell } from "@/lib/store";
const generalFields = require("./generalFields.json");
const defaultProps = require("./defaultProps.json");
export interface CustomInputCellProps {
  register: any;
  setValue?: any;
  inputType?: string;
  id: string;
  dataType: string;
  textColor: string;
  isLoading?: boolean;
  isSelect?: boolean;
  lookupValues?: string;
  trackColor?: string;
  rangeColor?: string;
  thumbColor?: string;
  borderColor?: string;
  label: string;
  prefix: string;
  suffix: string;
  disabled?: boolean;
  isOutput?: boolean;
  value?: any;
  padding: any;
  maxWidth: any;
  onChange: any;
  sliderColor: string;
  inputBgColor: any;
  defaultValue: any;
  min: any;
  textAlign: any;
  max: any;
  step: any;
  inputRadius: any;
  inputBorderWidth: any;
  inputBorderColor: any;
}

export const CellComponents = ({
  availableCells,
  isOutput,
  isLoading,
}: {
  setValue?: (id: string, value: any) => void;
  isLoading?: boolean;
  availableCells: ICell[];
  lookupValues?: string;
  isOutput?: boolean;
}) => {
  return availableCells.reduce((acc, cell) => {
    const dataType = cell.dataType;
    const disabled = isOutput ? true : false;

    const dynamicFields = {
      trackColor: { type: "text", label: "Track Color" },
      rangeColor: { type: "text", label: "Range Color" },
      thumbColor: { type: "text", label: "Thumb Color" },
      borderColor: { type: "text", label: "Border Color" },
      step: { type: "text", label: "Step" },
      min: { type: "text", label: "Min" },
      max: { type: "text", label: "Max" },
    };

    const commonFields = {
      inputType: {
        type: "custom",
        label: "Input Field Type",
        //@ts-ignore
        render: ({ name, onChange, value: value2 }) => {
          let currentValueByDataType = "text";
          if (dataType === "Number") {
            currentValueByDataType = "slider";
          }
          if (value2) {
            currentValueByDataType = value2;
          }
          if (dataType === "List") {
            currentValueByDataType = "dropdown";
          }

          const options = [
            { label: "Text", value: "text" },
            { label: "Slider", value: "slider" },
            { label: "Dropdown", value: "dropdown", disabled },
          ];

          return (
            <div className="flex flex-col">
              <FieldLabel label="Input Field Type" />
              {options.map((option) => (
                <label key={option.value} className="flex items-center">
                  <input
                    type="radio"
                    name={name}
                    className="mr-2"
                    value={option.value}
                    checked={currentValueByDataType === option.value}
                    onChange={(e) => {
                      onChange(e.currentTarget.value);
                    }}
                    disabled={option.value === "dropdown" || option.disabled}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          );
        },
      },
      ...generalFields,
    };

    //@ts-ignore
    acc[cell.label] = {
      render: (props: Omit<CustomInputCellProps, "id" | "label">) => (
        <CustomInputCell
          {...props}
          id={`${cell.id}`}
          isSelect={dataType === "List"}
          dataType={dataType}
          lookupValues={cell.lookupValues}
          defaultValue={cell.defaultValue}
          isLoading={isLoading}
          disabled={disabled}
          isOutput={isOutput}
          label={cell.label}
        />
      ),
      defaultProps,
      fields: commonFields,
      //@ts-ignore
      resolveFields: async ({ props }) => {
        const { inputType } = props;

        const filteredFields = {
          inputType: commonFields.inputType,
          ...generalFields,
          ...(inputType === "slider" ? dynamicFields : {}),
        };

        if (inputType !== "slider") {
          delete filteredFields.trackColor;
          delete filteredFields.rangeColor;
          delete filteredFields.thumbColor;
          delete filteredFields.borderColor;
          delete filteredFields.step;
          delete filteredFields.min;
          delete filteredFields.max;
        }

        if (inputType === "slider") {
          delete filteredFields.inputBgColor;
        }

        // Ensure 'min' field is included and defaults to 0 if not specified
        if (!filteredFields.min) {
          filteredFields.min = { type: "text", label: "Min", default: 0 };
        }

        return filteredFields;
      },
    };

    return acc;
  }, {});
};
