import React from "react";
import { FormLabel } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useFormContext } from "react-hook-form";

interface TextInputProps {
  label: string;
  id: string;
  defaultValue: number;
  disabled: boolean;
  isLoading: boolean;
  textColor: string;
}

export const TextInput = ({
  label,
  id,
  disabled,
  isLoading,
  defaultValue,
  textColor,
}: TextInputProps) => {
  const { register } = useFormContext();
  if (!register) {
    return <p>loading..</p>;
  }
  return (
    <div className="w-full md:w-[50%] m-auto">
      <FormLabel style={{ color: textColor }}>{label}</FormLabel>
      <Input
        placeholder={label}
        disabled={disabled || isLoading}
        {...register(id)}
        defaultValue={defaultValue}
        className="w-full"
      />
    </div>
  );
};
