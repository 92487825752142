import GeneralTitle from "@/components/GeneralTitle";
import UserTitle from "@/components/UserTitle";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { setData } from "@/lib/setDataClient";
import { useStore } from "@/lib/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { TrashIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

export interface Iimage {
  id: number;
  image: string;
  thumbnail: string;
}

const schema = z.object({
  alt: z.string().nonempty("Alt text cannot be empty"),
  name: z.string().nonempty("Name cannot be empty"),
});

export default function IndexPage({ params }: { params: { id: string } }) {
  const selectedImage = useStore((state) => state.selectedImage);

  const { setValue, ...form } = useForm({
    defaultValues: { alt: selectedImage?.alt || "", name: selectedImage?.name },
    resolver: zodResolver(schema), // Use Zod resolver
  });
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  async function handleRemoveImage() {
    const res = await fetch("/api/media?id=" + params.id, { method: "DELETE" });
    toast({
      title: "Image Deleted",
    });
    navigate("/workspace/media");
  }

  async function onSubmit(data: any) {
    setLoading(true);
    var formData = JSON.stringify(data);
    const res = await setData({
      path: `/media/${params.id}`,
      formData,
      method: "PUT",
    });
    toast({
      title: "Image Updated",
    });
    setLoading(false);
  }

  return (
    <div className="container relative">
      <div className="flex justify-between flex-col lg:flex-row mb-3 pb-8">
        <div className="flex items-center space-x-4">
          <Button onClick={() => navigate("/workspace/media")}>Back</Button>
          <GeneralTitle title="Single Image" />
        </div>
        <div
          className="bg-black p-1 rounded-full absolute right-3 top-2 cursor-pointer"
          onClick={() => handleRemoveImage()}
        >
          <TrashIcon className="w-4 h-4 text-white" />
        </div>
      </div>
      <Form {...form} setValue={setValue}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-full max-w-xl"
        >
          <div className="flex flex-row">
            <img
              src={`${process.env.REACT_APP_BASE_URL}${selectedImage?.imageUrl}?dimensions=400x400`}
              alt={selectedImage?.alt || ""}
              // @ts-ignore
              crossorigin="anonymous"
              width={400}
              height={400}
              className="object-cover transition-all"
            />
            <div className="flex flex-col w-full ml-2">
              <FormField
                control={form.control}
                name="alt"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Alt Text"
                        className="w-full p-2 mb-2 border rounded"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Asset Name"
                        className="w-full p-2 mb-2 border rounded"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button loading={loading} type="submit">
                Update image
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
