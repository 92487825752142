import React from "react";
import { ComponentConfig } from "@measured/puck";
import styles from "./styles.module.css";
import getClassNameFactory from "@/lib/getClassNameFactory";
import { Section } from "../../../components/Section";
import { NextLink } from "@/components/ui/Link";

const getClassName = getClassNameFactory("ButtonGroup", styles);

export type ButtonGroupProps = {
  align?: string;
  buttons: {
    label: string;
    href: string;
    variant: "primary" | "secondary";
    textColor?: string;
    backgroundColor?: string;
  }[];
  backgroundColor?: string;
  textColor?: string;
  borderRadius?: string;
  padding?: string;
};

export const ButtonGroup: ComponentConfig<ButtonGroupProps> = {
  fields: {
    buttons: {
      type: "array",
      getItemSummary: (item) => item.label || "Button",
      arrayFields: {
        label: { type: "text" },
        href: { type: "text" },
        textColor: { type: "text" },
        backgroundColor: { type: "text" },
        variant: {
          type: "radio",
          options: [
            { label: "primary", value: "primary" },
            { label: "secondary", value: "secondary" },
          ],
        },
      },
      defaultItemProps: {
        label: "Button",
        textColor: "#fff",
        backgroundColor: "#000",
        href: "#",
        variant: "primary",
      },
    },
    align: {
      type: "radio",
      options: [
        { label: "left", value: "left" },
        { label: "center", value: "center" },
      ],
    },
    backgroundColor: { type: "text" },
    textColor: { type: "text" },
    borderRadius: { type: "text" },
    padding: { type: "text" },
    // New fields for styling
  },
  defaultProps: {
    buttons: [{ label: "Learn more", href: "#", variant: "primary" }],
    backgroundColor: "#ffffff", // Default white background
    textColor: "#000000", // Default black text
    borderRadius: "4px", // Default radius
    padding: "16px", // Default padding
  },
  render: ({
    align,
    buttons,
    backgroundColor,
    textColor,
    borderRadius,
    padding,
  }) => {
    return (
      <Section
        className={getClassName({ center: align === "center" })}
        style={
          {
            // Apply new style properties
          }
        }
      >
        <div className={getClassName("actions")}>
          {buttons.map((button, i) => (
            <NextLink
              key={i}
              href={button.href}
              style={{
                backgroundColor: button.backgroundColor,

                color: button.textColor,
                borderRadius,
                padding,
                // Apply new style properties
              }}
            >
              {button.label}
            </NextLink>
          ))}
        </div>
      </Section>
    );
  },
};
