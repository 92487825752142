import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { loginUser } from "@/lib/login";
import { useStore } from "@/lib/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as z from "zod";

const LoginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
});

export default function LoginPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const { setError, ...form } = useForm({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  async function onSubmit(data: z.infer<typeof LoginSchema>) {
    setIsLoading(true)
    const result = await loginUser(data.email, data.password);

    if (result.success && result.data?.token) {
      useStore.setState({
        token: result.data.token,
        user: result.data.user,
        currentPlan: result.data.user.currentPlan,
      });
      setIsLoading(false)
      navigate("/workspace");
    } else {
      setIsLoading(false)
      console.log("Login error:", result.error);
      if (result.error.toLowerCase().includes("email")) {
        setError("email", { type: "server", message: result.error });
      } else if (result.error.includes("password")) {
        setError("password", { type: "server", message: result.error });
      }
    }
  }

  return (
    <div className="container relative flex flex-col justify-center items-center mt-10">
      <h1 className="text-3xl mb-4">Login</h1>
      <Form {...form} setError={setError}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-full max-w-md space-y-3"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input type="password" placeholder="Password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-center">
            <Button className="w-full" type="submit" loading={isLoading}>
              Login
            </Button>
          </div>
        </form>
      </Form>
      <div className="mt-8 max-w-md w-full ">
        <Separator className="my-4" />
        <p className="text-center mb-4">Don&apos;t have an account?</p>
        <Button
          onClick={() => navigate("/register")}
          className="w-full"
          type="button"
        >
          Register
        </Button>
        <Separator className="my-4" />
        <p className="text-center mb-4">Forgot your password?</p>
        <Button
          onClick={() => navigate("/forgot-password")}
          className="w-full"
          type="button"
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
}
