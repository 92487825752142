import SkeletonPage from "@/components/SkeletonPage";
import { Badge } from "@/components/ui/badge";
import { NextLink } from "@/components/ui/Link";
import { getData } from "@/lib/fetchDataClient";
import { useEffect, useState } from "react";
import { ToolImage } from "./components/ToolImage";
export interface Iimage {
  id: number;
  image: string;
  alt: string;
  name: string;
  thumbnail: string;
}
export interface IimageResponse {
  images: Iimage[];
  allowedImages: number;
}
export default function IndexPage() {
  const [useImages, setImages] = useState<IimageResponse | null>(null);
  useEffect(() => {
    const runWait = async () => {
      const res = (await getData("/media")) as IimageResponse;
      setImages(res);
    };
    runWait();
  }, []);
  if (!useImages) {
    return <SkeletonPage />;
  }
  return (
    <div className="container relative">
      <div className="flex justify-between flex-col lg:flex-row mb-3 pb-8">
        <div className="flex items-center">
          <h3 className="scroll-m-20 text-lg font-semibold tracking-tight mr-3">
            Media Library
          </h3>

          <Badge variant={useImages.allowedImages === 0 ? "destructive" : null}>
            {useImages.allowedImages} images remaining
          </Badge>
        </div>
        <NextLink
          disabled={useImages.allowedImages === 0}
          className="mt-4 lg:mt-0 lg:self-end disabled"
          href={"/workspace/media/create"}
        >
          Add image
        </NextLink>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pb-4">
        {useImages.images.map((image) => (
          <ToolImage key={image.id} image={image} className="w-full" isMedia />
        ))}
      </div>
    </div>
  );
}
