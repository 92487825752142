import { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { FormLabel } from "@/components/ui/form";
import { formatValue } from "@/lib/utils";
import { Slider } from "../ui/slider";

interface SliderInputProps {
  label: string;
  id: string;
  max: number;
  min: number;
  step: number;
  defaultValue: number;
  onSliderChange?: (newValue: number[]) => void;
  disabled: boolean;
  isLoading: boolean;
  textColor: string;
  trackColor?: string;
  rangeColor?: string;
  thumbColor?: string;
  borderColor?: string;
}

export const SliderInput = ({
  label,
  max,
  min,
  step,
  onSliderChange,
  defaultValue,
  disabled,
  id,
  isLoading,
  trackColor,
  rangeColor,
  thumbColor,
  borderColor,
  textColor,
}: SliderInputProps) => {
  const { control, getValues, setValue } = useFormContext();
  const formValue = getValues(id);
  const [sliderValue, setSliderValue] = useState<number[]>(
    formValue ? [formValue] : [defaultValue]
  );

  useEffect(() => {
    setSliderValue(
      formValue ? [formValue] : [defaultValue]
    );
  }, [formValue, defaultValue]);

  useEffect(() => {
    if (formValue === undefined) {
      setValue(id, defaultValue);
    }
  }, [id, setValue, formValue, defaultValue]);

  return (
    <div className="slider-input m-auto w-full md:w-[50%] mt-4 mb-4">
      <h3
        className="scroll-m-20 text-2xl text-center font-semibold tracking-tight"
        style={{ color: textColor }}
      >
        {formatValue(sliderValue[0])}
      </h3>
      <FormLabel style={{ color: textColor }}>{label}</FormLabel>
      <Controller
        control={control}
        name={id}
        render={({ field }) => (
          <Slider
            trackColor={trackColor}
            rangeColor={rangeColor}
            thumbColor={thumbColor}
            borderColor={borderColor}
            max={max || defaultValue || 100}
            defaultValue={[defaultValue]}
            //@ts-ignore
            min={parseInt(min, 10) || 0}
            step={step}
            onValueChange={(value) => {
              setSliderValue(value);
              field.onChange(value[0]);
              if (onSliderChange) {
                onSliderChange(value);
              }
            }}
            value={field.value ? [field.value] : sliderValue}
            disabled={disabled || isLoading}
          />
        )}
      />
    </div>
  );
};
