import { useStore } from "./store";

export async function getData(endpoint: string) {
  // const url = `${process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1"}${endpoint}`;

  const url = `${
    process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1"
  }${endpoint}`;
  const token = useStore.getState().token;

  if (token) {
    const response = await fetch(url, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const res = await response.json();
    return res.data;
  } else {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const res = await response.json();
    return res.data;
  }
}
