import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { setData } from "@/lib/setDataClient";
import CryptoJS from "crypto-js";
import { useToast } from "@/components/ui/use-toast";
import SkeletonPage from "@/components/SkeletonPage";
import { useStore } from "@/lib/store";

const LoginPage = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { userId, code } = useParams<{ userId: string; code: string }>();

  useEffect(() => {
    const handleVerify = async () => {
      const hash = CryptoJS.SHA256(
        `${process.env.REACT_APP_HASH_SALT}-${userId}-${code}`
      ).toString();

      const res = await setData({
        path: `/tools/invite/${hash}`,
        method: "GET",
      });

      if (res?.email) {
        toast({
          title: "Tool Verified",
          description: "You have been verified, you now need to register...",
        });
        useStore.setState({ registerEmail: res?.email });
        navigate("/register");
      } else {
        toast({
          title: "Something went wrong",
          variant: "destructive",
          description: "Please try again",
        });
        navigate("/register");
      }
    };

    if (code && userId) {
      handleVerify();
    }
  }, [code, userId, toast, navigate]);

  return (
    <div className="container relative flex flex-col justify-center items-center mt-10">
      <h1 className="text-3xl mb-4">Verifying...</h1>
      <SkeletonPage />
    </div>
  );
};

export default LoginPage;
