import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import WorkspaceLayout from "./app/layout";
import InnerLayout from "./app/workspace/layout";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <WorkspaceLayout>
          <InnerLayout>
            <AppRoutes />
          </InnerLayout>
        </WorkspaceLayout>
      </BrowserRouter>
    </div>
  );
}

export default App;
