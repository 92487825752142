import { FontsProvider } from "@/components/FontsProvider";
import { ThemeProvider } from "@/components/providers";
import TopNavigator from "@/components/TopNavigator";
import { Toaster } from "@/components/ui/toaster";
import { siteConfig } from "@/config/site";
import React from "react";
import { Helmet } from "react-helmet";
import "./globals.css";

interface AppProps {
  children: React.ReactNode;
}

const App: React.FC<AppProps> = ({ children }) => {
  return (
    <>
      <Helmet>
        <html className="bg-white" lang="en" />
        <title>Toolcrafter</title>
        <meta name="description" content={siteConfig.description} />
        <meta
          name="keywords"
          content="Next.js, React, Tailwind CSS, Server Components, Radix UI"
        />
        <meta name="author" content="Toolcrafter" />
        <meta name="creator" content="Toolcrafter" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={siteConfig.url} />
        <meta property="og:title" content={siteConfig.name} />
        <meta property="og:description" content={siteConfig.description} />
        <meta property="og:site_name" content={siteConfig.name} />
        <meta property="og:image" content={siteConfig.ogImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={siteConfig.name} />
        <meta name="twitter:description" content={siteConfig.description} />
        <meta name="twitter:image" content={siteConfig.ogImage} />
        <meta name="twitter:creator" content="@toolcrafter" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="shortcut icon" href="/favicon-16x16.png" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href={`${siteConfig.url}/site.webmanifest`} />
      </Helmet>
      <Toaster />
      <FontsProvider>
        <ThemeProvider
          attribute="class"
          defaultTheme="system"
          enableSystem
          disableTransitionOnChange
        >
          <TopNavigator />
          <div className="flex flex-1 ">{children}</div>
        </ThemeProvider>
      </FontsProvider>
    </>
  );
};

export default App;
