import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import { Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
      disabled: {
        true: "disabled-link opacity-50 bg-slate-800",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);
//@ts-ignore
export interface NextLinkProps
  extends LinkProps,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  disabled?: boolean;
  loading?: boolean;
  href?: string;
  to?: string;
}

const NextLink = React.forwardRef<HTMLAnchorElement, NextLinkProps>(
  (
    {
      className,
      variant,
      size,
      disabled,
      loading,
      asChild = false,
      href,
      to,
      ...props
    },
    ref
  ) => {
    const combinedClassName = cn(
      buttonVariants({ variant, size, disabled: !!disabled }),
      className
    );

    const linkTo = to || href || "";

    if (loading) {
      return (
        <RouterLink
          to={linkTo}
          className={combinedClassName}
          ref={ref}
          {...props}
        >
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Please wait
        </RouterLink>
      );
    }

    if (disabled) {
      return (
        <span
          className={combinedClassName}
          ref={ref as React.RefObject<HTMLSpanElement>}
          {...props}
        />
      );
    }

    return (
      <RouterLink
        to={linkTo}
        className={combinedClassName}
        ref={ref}
        {...props}
      />
    );
  }
);

NextLink.displayName = "NextLink";

export { NextLink, buttonVariants };
