import SkeletonPage from "@/components/SkeletonPage";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { setData } from "@/lib/setDataClient";
import { useStore } from "@/lib/store";
import useToolData from "@/lib/useTool";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Label } from "recharts";

export default function CreditCardForm({ params }: { params: { id: string } }) {
  const { id } = params;
  const tool = useToolData(id);
  const { toast } = useToast();

  const token = useStore((state) => state.token);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      cardName: "",
      cardNumber: "",
      month: "",
      year: "",
      cvv: "",
    },
  });

  useEffect(() => {
    if (tool) {
      // Populate form with existing data if available
    }
  }, [tool]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const res = await setData({
        path: "/payment/initialPayment",
        formData: JSON.stringify({
          cardName: data.cardName,
          cardNumber: data.cardNumber,
          expiry: `${data.month}${data.year.split("20").pop()}`,
          cvv: data.cvv,
          amount: "120",
          item: tool?.details[0].id,
        }),
        method: "POST",
      });
      toast({
        title: "Success",
        description: "Card processing, redirecting to 3DS...",
      });
      const url = res.actionUrl;
      localStorage.setItem("cid", res.cid);

      if (url) {
        window.location.href = url;
      }
      if (res.success === false) {
        setLoading(false);
        toast({ title: "Error", description: "Failed to update credit card" });
      }
    } catch (error) {
      console.error(error);
      toast({ title: "Error", description: "Failed to update credit card" });
    } finally {
      //   setLoading(false);
    }
  };

  const handleSelectChange = (name: string, selectedValue: string) => {
    //@ts-ignore
    setValue(name, selectedValue, { shouldValidate: true });
  };

  if (!tool) return <SkeletonPage />;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:w-[50%]">
      <p className="text-md font-light tracking-tight text-black mr-3 mb-2">
        You currently are using the free version of Toolcrafter for this tool
      </p>
      <p className="text-md font-light tracking-tight text-black mr-3 mb-12">
        To upgrade please pay R120 per month, lorem ipsum
      </p>
      <Input
        placeholder="Card Name"
        {...register("cardName")}
        className="mt-10"
        required
      />
      <Input
        placeholder="Card Number"
        {...register("cardNumber")}
        required
        inputMode="numeric"
        pattern="\d{16}"
        maxLength={16}
      />
      <div className="grid grid-cols-3 gap-4">
        <div className="grid gap-2">
          <Label>Expires</Label>
          <Select
            onValueChange={(value) => handleSelectChange("month", value)}
            defaultValue=""
          >
            <SelectTrigger id="month" aria-label="Month">
              <SelectValue placeholder="Month" {...register("month")} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="01">January</SelectItem>
              <SelectItem value="02">February</SelectItem>
              <SelectItem value="03">March</SelectItem>
              <SelectItem value="04">April</SelectItem>
              <SelectItem value="05">May</SelectItem>
              <SelectItem value="06">June</SelectItem>
              <SelectItem value="07">July</SelectItem>
              <SelectItem value="08">August</SelectItem>
              <SelectItem value="09">September</SelectItem>
              <SelectItem value="10">October</SelectItem>
              <SelectItem value="11">November</SelectItem>
              <SelectItem value="12">December</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="grid gap-2">
          <Label>Year</Label>
          <Select
            onValueChange={(value) => handleSelectChange("year", value)}
            defaultValue=""
          >
            {" "}
            <SelectTrigger id="year" aria-label="Year">
              <SelectValue placeholder="Year" />
            </SelectTrigger>
            <SelectContent>
              {Array.from({ length: 10 }, (_, i) => (
                <SelectItem key={i} value={`${new Date().getFullYear() + i}`}>
                  {new Date().getFullYear() + i}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="grid gap-2">
          <Input
            placeholder="CVV"
            {...register("cvv")}
            required
            inputMode="numeric"
            pattern="\d{3}"
            maxLength={3}
          />
        </div>
      </div>
      <Button type="submit" loading={loading}>
        Submit
      </Button>
    </form>
  );
}
