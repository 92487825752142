import { Section } from "@/components/Section";
import { formatValue } from "@/lib/utils";
import React, { useEffect, useState } from "react";
import { SelectInput } from "./SelectInput";
import { SliderInput } from "./SliderInput";
import { TextInput } from "./TextInput";
import { useFormContext } from "react-hook-form";
import { CustomInputCellProps } from "./CellComponents";

export const CustomInputCell = ({
  setValue,
  inputType,
  id,
  textColor,
  isLoading,
  isSelect,
  lookupValues,
  label,
  prefix,
  suffix,
  disabled,
  isOutput,
  padding,
  maxWidth,
  trackColor,
  rangeColor,
  thumbColor,
  borderColor,
  defaultValue,
  min,
  textAlign,
  max,
  step,
}: CustomInputCellProps) => {
  const [useTmpVal, setTmpVal] = useState<string | number>(defaultValue);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setTmpVal(newValue);
    setValue && setValue(id, newValue);
  };

  const onSliderChange = (newValue: number[]) => {
    setTmpVal(newValue[0]);
    setValue && setValue(id, newValue[0]);
  };
  const form = useFormContext();
  const val = form.getValues(id);

  const isHidden =
    isOutput &&
    (val === null || val === undefined) &&
    !window.location.pathname.includes("design");
  const showOuput = window.location.pathname.includes("design");
  return (
    <Section
      className={`flex ${isHidden ? "hidden" : ""} ${textAlign === "center" ? "justify-center" : "justify-start"
        }`}
      padding={padding}
      maxWidth={maxWidth}
    >
      {inputType === "slider" && !isOutput && !isSelect && (
        <SliderInput
          label={label}
          max={max}
          trackColor={trackColor}
          rangeColor={rangeColor}
          thumbColor={thumbColor}
          borderColor={borderColor}
          id={id}
          min={min}
          step={step}
          disabled={disabled || false}
          isLoading={isLoading || false}
          textColor={textColor}
          defaultValue={defaultValue}
        />
      )}
      {isSelect && !isOutput && (
        <SelectInput
          useTmpVal={String(useTmpVal)}
          label={label}
          defaultValue={defaultValue}
          setValue={setValue}
          id={id} //@ts-ignore
          lookupValues={lookupValues}
          textColor={textColor}
        />
      )}
      {inputType !== "slider" && !isOutput && !isSelect && (
        <TextInput
          useTmpVal={String(useTmpVal)}
          label={label}
          defaultValue={defaultValue}
          handleInputChange={handleInputChange}
          id={id} //@ts-ignore
          disabled={disabled} //@ts-ignore
          isLoading={isLoading}
          textColor={textColor}
        />
      )}
      {((isOutput && val) || (showOuput && isOutput)) && (
        <h3
          style={{ textAlign: textAlign || "center", color: textColor }}
          className="mt-10 text-2xl text-left font-bold tracking-tight"
        >
          {label}: {formatValue(showOuput ? 100 : val, prefix, suffix, true)}
        </h3>
      )}
    </Section>
  );
};
