import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useStore } from "@/lib/store";
import { LogOut } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Avatar, AvatarFallback } from "./ui/avatar";
import { Button } from "./ui/button";
import { NextLink } from "./ui/Link";
export function UserNav() {
  const token = useStore.getState().token;
  const firstname = useStore.getState().user?.firstname || "A";
  const lastname = useStore.getState().user?.surname || "A";
  const inits = `${Array.from(firstname)[0]}${Array.from(lastname)[0]}`;
  const navigate = useNavigate();

  return (
    <>
      {token ? (
        <DropdownMenu>
          <DropdownMenuTrigger
            asChild
            className="border-2 border-slate-200 self-center cursor-pointer hover:border-slate-600 "
          >
            <Avatar>
              <AvatarFallback>{inits}</AvatarFallback>
            </Avatar>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-16">
            {/* <DropdownMenuLabel>My Account</DropdownMenuLabel> */}
            <DropdownMenuSeparator />
            {/* <DropdownMenuGroup>
              <Link className="flex self-center mt-2 mb-2" href="/profile">
                <User className="mr-2 h-4 w-4" />
                <span className="mt-[-3px]">Profile</span>
              </Link>
              <Link className="flex self-center mt-2 mb-2" href="/billing">
                <CreditCard className="mr-2 h-4 w-4 mt-[2px]" />
                <span className="mt-[-3px]">Billing</span>
              </Link>
            </DropdownMenuGroup>
            <DropdownMenuSeparator /> */}
            <Button
              className="flex self-center mt-2 mb-2"
              onClick={() => {
                useStore.setState({ token: null });
                navigate("/");
              }}
            >
              <LogOut className="mr-2 h-4 w-4" />
              <span className="mt-[-3px]">Logout</span>
            </Button>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <>
          <NextLink
            className="self-center mr-4 bg-secondary hover:bg-slate-50 hover:text-black text-secondary-foreground"
            href="/register"
          >
            Register
          </NextLink>
          <NextLink className="self-center " href="/">
            Login
          </NextLink>
        </>
      )}
    </>
  );
}
