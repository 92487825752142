import { useEffect } from "react";
import { getData } from "./fetchDataClient";
import { ITool, useStore } from "./store";

function useToolData(
  id: null | string,
  dontFetchTool?: boolean
): ITool | undefined {
  const tool = useStore((state) => state.selectedTool);

  useEffect(() => {
    let isActive = true;
    const fetchDataAsync = async () => {
      try {
        let url = `/tools/${id}`;
        let result;
        result = await getData(url);

        if (isActive) useStore.setState({ selectedTool: result });
      } catch (error) {
        console.error(error);
      }
    };
    if (id && !dontFetchTool) {
      fetchDataAsync();
    }

    return () => {
      useStore.setState({ selectedTool: undefined });
      isActive = false;
    };
  }, [id, dontFetchTool]);

  return tool;
}

export default useToolData;
