import React from "react";
import { Config } from "@measured/puck";
import { ButtonGroup, ButtonGroupProps } from "./blocks/ButtonGroup";
import { Card, CardProps } from "./blocks/Card";
import {
  CaclulatorBlock,
  CaclulatorBlockProps,
} from "./blocks/CaclulatorBlock";
import { Columns, ColumnsProps } from "./blocks/Columns";
import { Hero, HeroProps } from "./blocks/Hero";
import { Heading, HeadingProps } from "./blocks/Heading";
import { Flex, FlexProps } from "./blocks/Flex";
import { Logos, LogosProps } from "./blocks/Logos";
import { Stats, StatsProps } from "./blocks/Stats";
import { Text, TextProps } from "./blocks/Text";
import { VerticalSpace, VerticalSpaceProps } from "./blocks/VerticalSpace";
import Root, { RootProps } from "./root";
import { InputFormSubmitProps } from "@/app/workspace/[id]/design/InputFormSubmit";
import { FooterSubmitProps } from "@/app/workspace/[id]/design/Footer";
import { HeaderLogo, HeaderLogoProps } from "./blocks/HeaderLogo";
import { BarChart, BarChartProps } from "./blocks/BarChart";
import { ImageProps, Image } from "./blocks/Image";
import { FieldOption } from "@/components/FontsProvider";

type Props = {
  ButtonGroup: ButtonGroupProps;
  Card: CardProps;
  CaclulatorBlock: CaclulatorBlockProps;
  Columns: ColumnsProps;
  Hero: HeroProps;
  Heading: HeadingProps;
  Flex: FlexProps;
  Image: ImageProps;
  Logos: LogosProps;
  BarChart: BarChartProps;
  HeaderLogo: HeaderLogoProps;
  InputFormSubmit?: InputFormSubmitProps;
  // Footer?: FooterSubmitProps;
  Text: TextProps;
  VerticalSpace: VerticalSpaceProps;
};

export const initialData = {
  content: [],
  root: {
    title: "asdf",
    background: "green",
    fontFamily: "Arial", // Default font family
  },
  zones: {},
};

export const allowedDuplicateComponents = [
  "ButtonGroup",
  "Card",
  "CaclulatorBlock",
  "BarChart",
  "Image",
  "Columns",
  "Hero",
  "HeaderLogo",
  "Heading",
  "Flex",
  "Logos",
  // 'Stats',
  "Text",
  "VerticalSpace",
  // "Footer", // Add Footer to allowed duplicate components if needed
];

// We avoid the name config as next gets confused
const createConfig = (fonts: FieldOption[]): Config<Props, RootProps> => ({
  root: {
    fields: {
      title: {
        type: "text",
      },
      background: {
        type: "text",
        label: "Background Color",
      },
      fontFamily: {
        type: "select",
        options: fonts,
        label: "Font Family",
      },
    },
    //@ts-ignore
    render: Root,
  },
  //@ts-ignore
  categories: {
    calculatorSubmitButton: {
      title: "Calculator Submit Button",
      components: ["InputFormSubmit"],
    },
    // footer: {
    //   title: "Footer",
    //   components: ["Footer"],
    // },
    layout: {
      components: [
        "CaclulatorBlock",
        "Image",
        "Card",
        "HeaderLogo",
        "Columns",
        "Flex",
        "VerticalSpace",
        "Hero",
      ],
    },
    charts: {
      components: ["BarChart"],
    },
    typography: {
      components: ["Heading", "Text"],
    },
    interactive: {
      title: "Actions",
      components: ["ButtonGroup"],
    },
  },
  components: {
    ButtonGroup,
    Card,
    CaclulatorBlock,
    BarChart,
    Columns,
    //@ts-ignore
    Image: Image({}),
    //@ts-ignore
    HeaderLogo: HeaderLogo({}),
    Hero,
    Heading,
    Flex,
    Logos,
    // Stats,
    Text,
    VerticalSpace,
  },
});

export default createConfig;
