import { useNavigate } from "react-router-dom";

import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useStore } from "@/lib/store";
import { useDropzone } from "react-dropzone";
import { Button } from "@/components/ui/button";
export default function IndexPage({ params }: { params: { id: string } }) {
  const { setValue, ...form } = useForm({});
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const token = useStore((state) => state.token);
  const [fileName, setFileName] = useState("");

  const clearFile = () => {
    setFileName("");
    setValue("favicon", "");
  };
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (file) {
        setFileName(file.name); // Set the file name to state
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue("file", reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    },
    [setValue]
  );
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop,
      maxFiles: 1,
      accept: {
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
    });
  const dropzoneStyles = `border-2 ${
    isDragActive ? "w-1/2 border-blue-400 bg-blue-100" : "border-gray-500"
  } border-dashed rounded-md p-6 py-10 text-center transition-all `;

  const { toast } = useToast();

  const handleClose = (open: boolean) => {
    if (!open) {
      navigate("/workspace");
    }
  };

  async function onSubmit(data: any) {
    try {
      setIsLoading(true);
      if (!acceptedFiles[0]) return;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", token || "");
      const formdata = new FormData();
      formdata.append("file", acceptedFiles[0]);
      let toolUrl = `${
        process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1"
      }/tools/upload`;
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow" as RequestRedirect,
      };
      const response = await fetch(toolUrl, requestOptions);
      const res = await response.json();
      toast({
        title: "Spreadsheet uploaded",
      });
      setIsLoading(false);
      navigate(`/workspace/${res.data.uuid}/wizard`);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
        <Dialog defaultOpen onOpenChange={handleClose}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="text-xl mb-5">
                Upload a spreadsheet
              </DialogTitle>
              <DialogDescription>
                <div className="flex flex-col">
                  {fileName && (
                    <div className="flex items-center space-x-2 mb-3">
                      <span className="text-sm">{fileName}</span>
                      <button
                        type="button"
                        onClick={clearFile}
                        className="text-blue-500 hover:text-blue-600"
                      >
                        Clear
                      </button>
                    </div>
                  )}

                  <div
                    {...getRootProps()}
                    className={dropzoneStyles}
                    data-testid="dropzone"
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p>
                        Drag/drop an spreadsheet here, or click to select an
                        spreadsheet (.xls)
                      </p>
                    )}
                  </div>
                  <Button
                    onClick={onSubmit}
                    loading={isLoading}
                    disabled={!fileName}
                    className="mt-4 self-end"
                  >
                    Next Step
                  </Button>
                </div>
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
    </>
  );
}
