import React, { useEffect } from "react";

export type RootProps = {
  title: string;
  background: string;
  fontFamily?: string;
  children: React.ReactNode;
};

const Root: React.FC<RootProps> = ({
  title,
  background,
  fontFamily,
  children,
}) => {
  useEffect(() => {
    if (fontFamily) {
      const link = document.createElement("link");
      link.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(
        " ",
        "+"
      )}&display=swap`;
      link.rel = "stylesheet";
      document.head.appendChild(link);
    }
  }, [fontFamily]);

  return <div style={{ background, fontFamily }}>{children}</div>;
};

export default Root;
