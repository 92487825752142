import React, { useState } from "react";
import { Form, FormControl, FormField, FormItem, FormMessage } from "./ui/form";
import { Input } from "./ui/input";
import { useForm } from "react-hook-form";
import { toast } from "./ui/use-toast";
import { setData } from "@/lib/setDataClient";
import { useStore } from "@/lib/store";
import { Button } from "./ui/button";

function SendLinkAsEmail({ id }: { id: string }) {
  const form2 = useForm({});
  const [useSendingEmail, setSendingEmail] = useState<boolean>(false);
  const token = useStore((state) => state.token);
  async function sendEmail(data: any) {
    try {
      setSendingEmail(true);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", token || "");
      const res = await setData({
        path: `/tools/invite-viewer/${id}`,
        formData: JSON.stringify({
          displayName: data.displayName,
          email: data.email,
        }),
        method: "POST",
      });
      await toast({
        title: "Email Sent",
        //@ts-ignore
        description: `You sent the email to ${data.email}`,
      });
      setSendingEmail(false);

      // Reset form fields explicitly
      form2.setValue("email", "");
      form2.setValue("displayName", "");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Form {...form2}>
      <form
        onSubmit={form2.handleSubmit(sendEmail)}
        className="w-full  space-y-3"
      >
        <h5 className="text-lg mt-4 font-semibold tracking-tight mb-2">
          Share Via Email
        </h5>
        <FormField
          control={form2.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form2.control}
          name="displayName"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Display Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-left mb-16">
          <Button type="submit" loading={useSendingEmail}>
            Send Email
          </Button>
        </div>
      </form>
    </Form>
  );
}

export default SendLinkAsEmail;
