import React from "react";
import { ComponentConfig } from "@measured/puck";
import styles from "./styles.module.css";
import getClassNameFactory from "@/lib/getClassNameFactory";
import { DropZone } from "@measured/puck";
import { Section } from "../../../components/Section";

const getClassName = getClassNameFactory("Flex", styles);

export type FlexProps = {
  items: { minItemWidth?: number }[];
  minItemWidth: number;
  backgroundColor?: string;
  margin?: string;
  padding?: string;
  borderRadius?: string;
  borderColor?: string;
};

export const Flex: ComponentConfig<FlexProps> = {
  fields: {
    items: {
      type: "array",
      arrayFields: {
        minItemWidth: {
          label: "Minimum Item Width",
          type: "number",
        },
      },
      getItemSummary: (_, id) => `Item ${id || 0 + 1}`,
    },
    minItemWidth: {
      label: "Minimum Item Width",
      type: "number",
    },
    backgroundColor: { type: "text" },
    margin: { type: "text" },
    padding: { type: "text" },
    borderRadius: { type: "text" },
    borderColor: { type: "text" },
  },
  defaultProps: {
    items: [{}, {}],
    minItemWidth: 356,
    backgroundColor: "#ffffff", // Default white background
    margin: "0", // Default margin
    padding: "16px", // Default padding
    borderRadius: "4px", // Default radius
    borderColor: "#cccccc", // Default border color
  },
  render: ({ items, minItemWidth, backgroundColor, margin, padding, borderRadius, borderColor }) => {
    return (
      <Section >
        <div className={getClassName()}>
          {items.map((item, idx) => (
            <div
              key={idx}
              className={getClassName("item")}
              style={{ minWidth: item.minItemWidth || minItemWidth, backgroundColor, margin, padding, borderRadius, borderColor }}
            >
              <DropZone zone={`item-${idx}`} />
            </div>
          ))}
        </div>
      </Section>
    );
  },
};
