import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { setData } from "@/lib/setDataClient";
import { zodResolver } from "@hookform/resolvers/zod";
import CryptoJS from "crypto-js";
import { useForm } from "react-hook-form";
import * as z from "zod";

const PasswordSchema = z.object({
  password: z.string().min(6),
  confirmPassword: z.string().min(6),
});

export default function LoginPage() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { userId, code } = useParams<{ userId: string; code: string }>();

  const { setError, ...form } = useForm({
    resolver: zodResolver(PasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const handleVerify = async (data: z.infer<typeof PasswordSchema>) => {
    if (data.password !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: "Passwords do not match",
      });
      return;
    }

    const hash = CryptoJS.SHA256(
      `${process.env.REACT_APP_HASH_SALT}-${userId}-${code}`
    ).toString();

    const res = await setData({
      path: `/users/forgotpassword`,
      method: "POST",
      formData: JSON.stringify({
        code: hash,
        password: data.password,
      }),
    });

    if (res?.success) {
      toast({
        title: "Email Verified",
        description: "You have been verified, redirecting...",
      });
      navigate("/");
    } else {
      toast({
        title: "Something went wrong",
        variant: "destructive",
        description: "Please try again",
      });
      navigate("/forgot-password");
    }
  };

  return (
    <div className="container relative flex flex-col justify-center items-center mt-10">
      <h1 className="text-3xl mb-4">Please confirm your new password</h1>
      <Form {...form} setError={setError}>
        <form
          onSubmit={form.handleSubmit(handleVerify)}
          className="w-full max-w-md space-y-3"
        >
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input type="password" placeholder="Password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="Confirm Password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-center">
            <Button className="w-full" type="submit">
              Verify
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
