import SkeletonPage from "@/components/SkeletonPage";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TooltipProvider } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { setData } from "@/lib/setDataClient";
import { useStore } from "@/lib/store";
import useToolData from "@/lib/useTool";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";
import { InfoIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export default function IndexPage({ params }: { params: { id: string } }) {
  const { register, handleSubmit, setValue } = useForm<{
    customDomain: string;
  }>();
  const id = params.id;
  const tool = useToolData(id);
  const token = useStore((state) => state.token);
  const { toast } = useToast();
  const [protocol, setProtocol] = useState("https");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tool && tool.details[0].customDomain) {
      const urlParts = tool.details[0].customDomain.match(/(https?:\/\/)(.*)/);
      if (urlParts) {
        setProtocol(urlParts[1] + "://");

        setValue("customDomain", urlParts[2]);
      }
    }
  }, [tool, setValue]);

  const onSubmit = async (data: { customDomain: string }) => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token || "");

    if (data.customDomain) {
      const res = await setData({
        path: `/tools/customdomain/${id}`,
        method: "PUT",
        formData: JSON.stringify({ domain: `${protocol}${data.customDomain}` }),
      });

      toast({
        title: "Tool Updated",
        //@ts-ignore
        description: `You have updated your tool`,
      });
    }
    // Continue with your submission logic
    setLoading(false);
  };

  if (!tool) return <SkeletonPage />;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <p className="tracking-tight">Enter your Custom Domain</p>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <button disabled aria-label="Info">
                  <InfoIcon />
                </button>
              </TooltipTrigger>
              <TooltipContent side="right" align="center">
                <p>Please create an CName record linking your</p>
                <p>domain to https://toolcrafter.io</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex gap-2">
          <div className="w-[90px]">
            <Select
              defaultValue={protocol}
              onValueChange={setProtocol}
              value={protocol}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="w-[120px] w-8">
                <SelectGroup>
                  <SelectItem value="http://">http://</SelectItem>
                  <SelectItem value="https://">https://</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <input
            {...register("customDomain")}
            placeholder="Enter your domain"
            className="flex-grow p-2 border rounded"
          />
        </div>
        <div className="w-full pr-2 mb-6 lg:mb-0">
          <Button loading={loading}>Submit</Button>
        </div>
      </form>
    </div>
  );
}
