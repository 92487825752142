import { ITool } from "@/lib/store";
import { TrashIcon } from "@radix-ui/react-icons";
import { Control, useWatch, useFormContext } from "react-hook-form";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs";
import { useEffect, useState } from "react";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "./ui/form";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "./ui/select";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

interface IField {
  id: string;
  sheet: string;
  cell: string;
  label: string;
  dataType: string;
  defaultValue: string;
  type: string;
  lookupValues: string;
}

type Cells =
  | "inputs"
  | "outputs"
  | `inputs.${number}`
  | `inputs.${number}.sheet`
  | `inputs.${number}.cell`
  | `inputs.${number}.label`
  | `inputs.${number}.dataType`
  | `inputs.${number}.type`
  | `inputs.${number}.defaultValue`
  | `inputs.${number}.lookupValues`
  | `outputs.${number}`
  | `outputs.${number}.sheet`
  | `outputs.${number}.cell`
  | `outputs.${number}.label`
  | `outputs.${number}.dataType`
  | `outputs.${number}.type`
  | `outputs.${number}.defaultValue`
  | `outputs.${number}.lookupValues`;

interface CellFieldsProps {
  fields: IField[];
  append: Function;
  remove: Function;
  control?: Control<{ inputs: IField[] }, any>;
  outputControl?: Control<{ outputs: IField[] }, any>;
  title: string;
  hideTitle?: boolean;
  namePrefix: string;
  setValue: any;
  tool: ITool;
  namedRanges: Record<string, any>;
}

export default function CellFields({
  fields,
  append,
  remove,
  control,
  hideTitle,
  setValue,
  title,
  outputControl,
  namePrefix,
  tool,
  namedRanges = {},
}: CellFieldsProps) {

  const [openItem, setOpenItem] = useState<string | null>(fields[0]?.id || null);
  const applicableNamedRanges = namedRanges[namePrefix] || [];



  const watchedFields = useWatch({//@ts-ignore
    control: control || outputControl,//@ts-ignore
    name: namePrefix,
    defaultValue: fields,
  });
  useEffect(() => {
    console.log("Watched Fields:", watchedFields);
  }, [watchedFields]);


  useEffect(() => {
    fields.forEach((field, index) => {
      const hasNamedRange = applicableNamedRanges && applicableNamedRanges.length > 0;
      setValue(`${namePrefix}[${index}].type`, hasNamedRange ? "namedRange" : "cell");
    });
  }, [namedRanges, fields, namePrefix, setValue]);

  useEffect(() => {
    if (fields.length > 0) {
      setOpenItem(fields[fields.length - 1].id);
    }
  }, [fields]);


  return (
    <>
      {!hideTitle && (
        <h5 className="text-lg font-semibold tracking-tight text-black mr-3 mb-6">
          {title}
        </h5>
      )}
      <Accordion
        type="single"
        collapsible
        className="w-full"//@ts-ignore
        value={openItem}
        onValueChange={(value) => setOpenItem(value)}
      >
        {fields.map((field, index) => {
                      console.log("🚀 ~ watchedFields[index]?.", watchedFields[index]?.type === "cell" || applicableNamedRanges.length > 0)

          return(
          <AccordionItem key={field.id} value={field.id}>
            {fields.length > 1 && (
              <AccordionTrigger>
                {openItem === field.id
                  ? null//@ts-ignore
                  : ` ${field.sheet ? "Sheet: " + field.sheet : ""} ${field.namedRange ? "Named Range: " + field.namedRange : ""
                  } ${field.label ? "Label: " + field.label : ""}`}
              </AccordionTrigger>
            )}
            <AccordionContent>
              {openItem === field.id && (
                <>
                  <div className="mb-4 ml-1 mr-1">
                    <div className="space-y-2">
                      <div className="space-y-1">
                        <FormField
                          control={control}//@ts-ignore
                          name={`${namePrefix}[${index}].type` as unknown as Cells}
                          render={({ field: typeField }) => (
                            <FormItem>
                              <FormLabel>Type</FormLabel>
                              <Tabs
                                value={typeField.value as string}
                                onValueChange={(value) => {
                                  typeField.onChange(value);
                                  if (value === "namedRange") {
                                    setValue(`${namePrefix}[${index}].sheet`, "");
                                  }
                                }}
                              >
                                <TabsList className="grid w-full grid-cols-2">
                                  <TabsTrigger value="cell">Cell Address</TabsTrigger>
                                  <TabsTrigger value="namedRange">Named Range</TabsTrigger>
                                </TabsList>
                              </Tabs>
                            </FormItem>
                          )}
                        />
                      </div>
                      {watchedFields[index]?.type === "cell" ? (
                        <>      
                          <FormField
                            control={control}//@ts-ignore
                            name={`${namePrefix}[${index}].sheet` as unknown as Cells}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Sheet</FormLabel>
                                <Select
                                  onValueChange={field.onChange}//@ts-ignore
                                  value={field.value}
                                >
                                  <FormControl>
                                    <SelectTrigger data-testid="sheet-page-select">
                                      <SelectValue placeholder="Sheet" />
                                    </SelectTrigger>
                                  </FormControl>
                                  <SelectContent>
                                    {tool?.details[0].sheets.map(({ id, name }) => (
                                      <SelectItem key={id} value={name}>
                                        {name}
                                      </SelectItem>
                                    ))}
                                  </SelectContent>
                                </Select>
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={control}//@ts-ignore
                            name={`${namePrefix}[${index}].cell` as unknown as Cells}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>Cell</FormLabel>
                                <FormControl>
                                  <Input
                                    placeholder="Cell"
                                    data-testid="inputcell"
                                    value={typeof field.value === "string" ? field.value : ""}
                                    onChange={field.onChange}
                                    className="w-full"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                           <FormField
                          control={control}//@ts-ignore
                          name={`${namePrefix}[${index}].label` as unknown as Cells}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Label</FormLabel>
                              <FormControl>
                                <Input
                                  placeholder="Label"
                                  data-testid="outputcell"
                                  value={typeof field.value === "string" ? field.value : ""}
                                  onChange={field.onChange}
                                  className="w-full"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        </>
                      ) : (
                        <>{applicableNamedRanges.length > 0 ? (<><FormField
                          control={control}//@ts-ignore
                          name={`${namePrefix}[${index}].namedRange` as unknown as Cells}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Named Range</FormLabel>
                              <Select
                                onValueChange={field.onChange}//@ts-ignore
                                value={field.value}
                              >
                                <FormControl>
                                  <SelectTrigger data-testid="named-range-select">
                                    <SelectValue placeholder="Named Range" />
                                  </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                  {applicableNamedRanges.map((name: string) => (
                                    <SelectItem key={name} value={name}>
                                      {name}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </FormItem>
                            
                          )}
                        />
                          <FormField
                          control={control}//@ts-ignore
                          name={`${namePrefix}[${index}].label` as unknown as Cells}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Label</FormLabel>
                              <FormControl>
                                <Input
                                  placeholder="Label"
                                  data-testid="outputcell"
                                  value={typeof field.value === "string" ? field.value : ""}
                                  onChange={field.onChange}
                                  className="w-full"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        /></>) : (
                          <>
                            <p className="text-md tracking-tight text-black mt-3 mb-6">
                              You dont have any named ranges set in this spreadhsheet
                            </p>
                          </>
                        )}</>
                      )}
              
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <Button
                      variant="outline"
                      onClick={() => {
                        remove(index);
                        if (openItem === field.id) {
                          setOpenItem(null);
                        }
                      }}
                      disabled={index === 0}
                    >
                      <TrashIcon className="w-4 h-4 text-red-500" />
                    </Button>
                  </div>
                </>
              )}
            </AccordionContent>
          </AccordionItem>
        )})}
      </Accordion>
      <div className="w-full flex justify-end flex-col pt-3">
        <Button
          className="align-end bg-white text-black border border-black hover:bg-slate-100"
          type="button"
          onClick={() => {
            const newFieldId = Math.random().toString(36).substr(2, 9);
            append({
              id: newFieldId,
              sheet: "",
              cell: "",
              label: "",
              dataType: "",
              defaultValue: "",
              lookupValues: "",
            });
            setOpenItem(newFieldId);
          }}
        >
          Add Cell
        </Button>
      </div>
    </>
  );
}
