export async function loginUser(email: string, password: string) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ email, password });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow" as RequestRedirect,
  };

  const baseUrl =
    process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1";
  const url = `${baseUrl}/users/login`;

  try {
    const response = await fetch(url, requestOptions);

    const res = await response.json();

    if (response.ok) {
      // Handle any revalidation or state update here
      return {
        success: true,
        data: { user: res.data.user, token: res.data.token },
      };
    } else if (response.status === 403) {
      const errorRes = await response.json();
      return { success: false, error: errorRes.error };
    } else {
      return { success: false, error: "Failed to fetch data" };
    }
  } catch (error) {
    return { success: false, error: "Unexpected error occurred" };
  }
}
