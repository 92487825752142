import SkeletonPage from "@/components/SkeletonPage";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { CopyIcon } from "@radix-ui/react-icons";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import SendLinkAsEmail from "@/components/SendLinkAsEmail";
import { Checkbox } from "@/components/ui/checkbox";
import { setData } from "@/lib/setDataClient";
import { useStore } from "@/lib/store";
import useToolData from "@/lib/useTool";
import { useDropzone } from "react-dropzone";

export default function IndexPage({ params }: { params: { id: string } }) {
  const id = params.id;
  const tool = useToolData(id);

  const { toast } = useToast();
  const token = useStore((state) => state.token);
  const [fileName, setFileName] = useState("");
  const [currentPlan, setCurrentPlan] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState(tool?.details[0]?.isPublished);
  const clearFile = () => {
    setFileName("");
    setValue("favicon", "");
  };
  const { setValue, register, handleSubmit, reset, watch, ...form } = useForm(
    {}
  );
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (file) {
        setFileName(file.name);
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue("favicon", reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    },
    [setValue]
  );

  const copyIframeCode = () => {
    navigator.clipboard.writeText(
      `<iframe src="${
        tool?.details[0]?.customDomain || `https://toolcrafter.io/${id}`
        // `${process.env.REACT_APP_PUBLIC_DOMAIN}/${id}`
      }" width="100%" height="100%"></iframe>`
    );
    toast({
      title: "Copied",
      description: "Embed code copied to clipboard!",
    });
  };

  const copyDomain = () => {
    navigator.clipboard.writeText(
      tool?.details[0]?.customDomain || `https://toolcrafter.io/${id}`
      // `${process.env.REACT_APP_PUBLIC_DOMAIN}/${id}`
    );
    toast({
      title: "Copied",
      description: "Domain copied to clipboard!",
    });
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop, maxFiles: 1, accept: { "image/png": [".png"] } });

  const dropzoneStyles = `border-2 ${
    isDragActive ? "w-1/2 border-blue-400 bg-blue-100" : "border-gray-500"
  } border-dashed rounded-md p-6 py-10 text-center transition-all `;

  useEffect(() => {
    if (tool) {
      reset({
        name: tool?.details[0].name,
        favicon: tool?.details[0].spreadsheet,
        hideFooter: tool?.details[0].hideFooter === 1,
      });
    }
  }, [tool, reset]);

  useEffect(() => {
    const currentPlanCookie = Cookies.get("currentPlan");
    const plan = (currentPlanCookie && JSON.parse(currentPlanCookie)) || {};
    setCurrentPlan(plan);
  }, []);

  async function onSubmit(data: any) {
    try {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", token || "");

      if (acceptedFiles[0]) {
        await setData({
          path: `/tools/favicon/${id}`,
          formData: JSON.stringify({ favicon: data.favicon }),
          method: "POST",
        });
        toast({
          title: "Tool Updated",
          description: `You have updated your tool favicon`,
        });
      }

      if (data.name) {
        const res = await setData({
          path: `/tools/name/${id}`,
          formData: JSON.stringify({ name: data.name }),
          method: "POST",
        });
        toast({
          title: "Tool Updated",
          description: `You have updated your tool name to ${res.name}`,
        });
      }

      if (data.hideFooter !== undefined) {
        await setData({
          path: `/tools/show-footer/${id}/${data.hideFooter ? "1" : "0"}`,
          method: "PUT",
        });
        toast({
          title: "Tool Updated",
          description: `Footer visibility updated`,
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  if (!tool) return <SkeletonPage />;

  return (
    <div className="grid w-full md:w-[50%] items-center gap-1.5 mb-10">
      <Form
        {...register}
        setValue={setValue}
        watch={watch}
        reset={reset}
        handleSubmit={handleSubmit}
        register={register}
        {...form}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-3">
          <Input
            {...register("name")}
            placeholder="Tool name"
            className="w-full p-2 border rounded"
          />
          <div className="flex items-center mt-4">
            <Checkbox
              {...register("hideFooter")}
              id="hide-footer"
              disabled={currentPlan.id === 1}
              checked={!!watch("hideFooter")}
              onCheckedChange={(checked) => setValue("hideFooter", checked)}
            />
            <label htmlFor="hide-footer" className="ml-2 text-sm text-gray-600">
              Hide footer
            </label>
            {currentPlan.id === 1 && (
              <p className="text-red-500 ml-2 text-sm">
                Paid plan required to hide footer
              </p>
            )}
          </div>
          <h5 className="text-lg font-semibold tracking-tight mr-3 mb-3">
            Favicon
          </h5>
          <div className="relative">
            {fileName && (
              <div className="flex items-center space-x-2 mb-3">
                <span className="text-sm">{fileName}</span>
                <button
                  type="button"
                  onClick={clearFile}
                  className="text-blue-500 hover:text-blue-600"
                >
                  Clear
                </button>
              </div>
            )}

            <div {...getRootProps()} className={dropzoneStyles}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag/drop an image here, or click to select an image</p>
              )}
            </div>
          </div>
          <Button loading={loading} type="submit">
            Submit
          </Button>
          <div className="flex justify-between items-center">
            <div>
              <h5 className="text-lg font-semibold tracking-tight mt-4 mb-4">
                Domain
              </h5>
              <p>
                {tool?.details[0]?.customDomain ||
                  `https://toolcrafter.io/${id}`}
                {/* `${process.env.REACT_APP_PUBLIC_DOMAIN}/${id}`} */}
              </p>
            </div>
            <button
              type="button"
              onClick={copyDomain}
              className="flex items-center bg-black text-white p-2 rounded"
            >
              <CopyIcon className="text-white" />
            </button>
          </div>
          {!isPublished && (
            <div className="bg-yellow-100 text-yellow-700 p-4 rounded-md mb-4">
              Tool Is currently not published. For Iframe to work, this tool
              must be published
              <Button
                variant="outline"
                className="ml-2"
                onClick={async () => {
                  setLoading(true);
                  await setData({
                    path: `/tools/publish/${id}/1`,
                    method: "PUT",
                  });
                  setLoading(false);
                  setIsPublished(true);
                  toast({
                    title: "Tool Published",
                    description: "Your tool has been published!",
                  });
                }}
              >
                Publish Now
              </Button>
            </div>
          )}
          <div className="flex flex-col mt-4 relative">
            <h5 className="text-lg font-semibold tracking-tight mb-4">
              Embed Iframe
            </h5>
            <textarea
              className="w-full p-2 border rounded"
              readOnly
              value={`<iframe src="${
                tool?.details[0].customDomain ||
                `https://toolcrafter.io/view/${id}`
                // `${process.env.REACT_APP_PUBLIC_DOMAIN}/view/${id}`
              }"></iframe>`}
            />
            <button
              type="button"
              onClick={copyIframeCode}
              className=" absolute top-0 right-0  bg-black text-white p-2 rounded mt-2"
            >
              <CopyIcon className="text-white" />
            </button>
          </div>
        </form>
      </Form>
      <SendLinkAsEmail id={id}></SendLinkAsEmail>
    </div>
  );
}
