import classnames from "classnames";

//@ts-ignore
export const getGlobalClassName = (rootClass, options) => {
    if (typeof options === "string") {
        return `${rootClass}-${options}`;
    } else {
        const mappedOptions = {};
        for (let option in options) {
            //@ts-ignore
            mappedOptions[`${rootClass}--${option}`] = options[option];
        }

        return classnames({
            [rootClass]: true,
            ...mappedOptions,
        });
    }
};

const getClassNameFactory =
    //@ts-ignore
    (rootClass, styles, { baseClass = "" } = {}) =>
        (options = {}) => {
            let descendant: any = false;
            let modifiers: any = false;

            if (typeof options === "string") {
                descendant = options;
            } else if (typeof options === "object") {
                modifiers = options;
            }

            if (descendant) {
                return baseClass + styles[`${rootClass}-${descendant}`] || "";
            } else if (modifiers) {
                const prefixedModifiers = {};

                for (let modifier in modifiers) {
                    //@ts-ignore
                    prefixedModifiers[styles[`${rootClass}--${modifier}`]] =
                        modifiers[modifier];
                }

                const c = styles[rootClass];

                return (
                    baseClass +
                    classnames({
                        [c]: !!c, // only apply the class if it exists
                        ...prefixedModifiers,
                    })
                );
            } else {
                return baseClass + styles[rootClass] || "";
            }
        };

export default getClassNameFactory;