import { useFormContext, Controller } from "react-hook-form";
import { FormLabel } from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

interface SelectInputProps {
  label: string;
  id: string;
  lookupValues: string;
  textColor: string;
  defaultValue: string;
}

export const SelectInput = ({
  label,
  id,
  lookupValues,
  textColor,
  defaultValue,
}: SelectInputProps) => {
  const { control } = useFormContext();

  return (
    <div className="select-input m-auto w-full md:w-[50%] mt-4 mb-4">
      <FormLabel style={{ color: textColor }}>{label}</FormLabel>
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            value={field.value}
            onValueChange={field.onChange}
            defaultValue={defaultValue}
          >
            <SelectTrigger aria-label={label}>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {lookupValues.split("|").map((value, index) => (
                <SelectItem key={index} value={value}>
                  {value}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      />
    </div>
  );
};
