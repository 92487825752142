import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { getData } from "@/lib/fetchDataClient";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
interface Plan {
  id: number;
  planName: string;
  numberOfTools: number;
  costPerMonth: number;
}

export default function PlansPage() {
  const [plans, setPlans] = useState<Plan[]>([]);
  const navigate = useNavigate();

  const currentPlanCookie = Cookies.get("currentPlan");
  //@ts-ignore
  const currentPlan =
    (currentPlanCookie && JSON.parse(currentPlanCookie)) || {};
  useEffect(() => {
    getData("/payment/plans")
      .then((data) => {
        setPlans(data);
      })
      .catch((error) => {
        console.error("Failed to fetch plans:", error);
      });
  }, []);

  const handlePlanSelect = (planId: number) => {
    const currentPlanTemp = plans.find(
      (p) => p.id === parseInt(`${planId}` || "1", 10)
    );
    currentPlanTemp &&
      Cookies.set("currentPlanTemp", JSON.stringify(currentPlanTemp));
    navigate(`/workspace/billing?planId=${planId}`);
  };

  return (
    <div className="container relative">
      <div className="flex justify-between flex-col lg:flex-row mb-3 pb-8">
        <div className="flex items-center">
          <h3 className="scroll-m-20 text-lg font-semibold tracking-tight mr-3">
            Workspace Plans
          </h3>
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-2 lg:gap-4 md:flex-row mt-4 mb-16">
        {plans.map((plan) => (
          <Card
            key={plan.id}
            className="w-full text-center md:w-1/3 lg:w-1/4 p-4 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-200"
          >
            <div className="flex flex-col h-full justify-between">
              <div>
                <h2 className="text-2xl font-semibold mb-2">{plan.planName}</h2>
                <p className="text-sm font-light mb-4">
                  Tools:{" "}
                  {plan.numberOfTools > 10000
                    ? "Unlimited"
                    : plan.numberOfTools}
                </p>
                <p className="text-lg font-medium">R{plan.costPerMonth} pm</p>
              </div>
              <Button
                className="mt-4 self-center"
                disabled={plan.id === currentPlan.id}
                onClick={() => handlePlanSelect(plan.id)}
              >
                Select
              </Button>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}
