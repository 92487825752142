import { useEffect, useState } from "react";
import { getData } from "@/lib/fetchDataClient";
import CardSingle, { ICard } from "./CardSingle";

export default function CardList() {
  const [cards, setCards] = useState<ICard | null>(null);

  const fetchCards = async () => {
    try {
      const data = await getData("/tools");
      setCards(data);
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mb-12">
      {cards?.details?.map((card) => (
        <CardSingle key={card.uuid} {...card} refreshCards={fetchCards} />
      ))}
    </div>
  );
}
