import { setData } from "@/lib/setDataClient";
import { Menu } from "@headlessui/react";
import {
  ArrowRightIcon,
  DotsHorizontalIcon,
  GearIcon,
  Share1Icon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { useState } from "react";
import SendLinkAsEmail from "./SendLinkAsEmail";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { NextLink } from "./ui/Link";
export interface ICard {
  details: {
    name: string;
    enabled: "1" | "0";
    uuid: number;
    category: string;
    listingImage?: string;
  }[];
  summary: {
    toolsUsed: number;
    toolsAllowed: number;
    toolsLeft: number;
  };
}
export default function CardSingle({
  name,
  enabled,
  category,
  uuid,
  listingImage,
  refreshCards,
}: Readonly<{
  name: string;
  uuid: number;
  category: string;
  enabled: "1" | "0";
  listingImage?: string;
  refreshCards: () => void;
}>) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDeleteTool = async () => {
    await setData({
      path: `/tools/${uuid}`,
      method: "DELETE",
    });
    refreshCards();
  };
  return (
    <div
      className={`flex flex-col items-center bg-white ${
        enabled === "0" ? "opacity-50 pointer-events-none" : ""
      }`}
    >
      <div
        className={`border border-gray-700 w-full rounded-sm ${
          enabled === "0" ? "bg-gray-200" : "bg-white"
        }`}
      >
        <div
          className="relative group bg-center bg-cover h-[9em] w-full transition duration-300 ease-in-out group-hover:opacity-50"
          style={{ backgroundImage: `url(${listingImage})` }}
        >
          <div className="relative transform  bg-black bg-opacity-50 w-full h-full rounded-sm rounded-b-none text-white opacity-0 group-hover:opacity-100  transition duration-200 ease-in-out">
            <NextLink
              href={`/workspace/${uuid}/general`}
              className="absolute top-1 flex items-center justify-start  right-1 transform text-xs bg-white text-black p-2 rounded-md opacity-0 group-hover:opacity-100  transition duration-200 ease-in-out"
            >
              <span className="flex items-center ">
                <GearIcon className="" />
              </span>
            </NextLink>
            <NextLink
              href={`/workspace/${uuid}/design`}
              className="absolute top-1/2 flex items-center justify-start  left-1/2 transform text-xs -translate-x-1/2 -translate-y-1/2 bg-white text-black p-2 rounded-md opacity-0 group-hover:opacity-100  transition duration-200 ease-in-out"
            >
              Open Designer
              <span className="flex items-center mr-2">
                <ArrowRightIcon className="ml-2" />
              </span>
            </NextLink>
          </div>
        </div>

        <div className="border-b border-gray-300"></div>
        <div className="flex justify-between items-center p-2 px-3">
          <div className="flex items-center">
            <div className="bg-green-500 rounded-full h-4 w-4"></div>
            <h2 className="self-start ml-3 text-sm font-semibold">{name}</h2>
            <span className="ml-2 text-sm">{category}</span>
          </div>
          <Menu as="div" className="relative inline-block text-left z-50">
            <Menu.Button className="p-2 rounded-lg" data-testid="open-card">
              <DotsHorizontalIcon />
            </Menu.Button>
            <Menu.Items className="absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setDialogOpen(true)}
                    data-testid="share-card"
                    className={`${
                      active ? "bg-slate-800 text-white" : "text-black"
                    }  flex px-2 py-1 w-full text-left`}
                  >
                    <span className="flex pt-1 items-center mr-2">
                      <Share1Icon />
                    </span>
                    Share
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleDeleteTool}
                    data-testid="delete-card"
                    className={`${
                      active ? "bg-slate-800 text-white" : "text-black"
                    }  flex px-2 py-1 w-full text-left`}
                  >
                    <span className="flex pt-1 items-center mr-2">
                      <TrashIcon />
                    </span>{" "}
                    Delete
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Menu>
          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle className="text-xl mb-5">
                  Share this tool
                </DialogTitle>
                <DialogDescription>
                  <SendLinkAsEmail id={`${uuid}`}></SendLinkAsEmail>
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
