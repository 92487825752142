import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import PhoneInput from "react-phone-input-2";

import { useStore } from "@/lib/store";
import "react-phone-input-2/lib/material.css";

import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { useToast } from "@/components/ui/use-toast";
import { setData } from "@/lib/setDataClient";
import { useEffect, useState } from "react";
const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);
const LoginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(6),
  firstname: z.string(),
  surname: z.string(),
  phone: z.string().regex(phoneRegex, "Invalid Phone Number"),
});

export default function RegisterPage() {
  const [useLoading, setLoading] = useState(false);
  const registerEmail = useStore((state) => state.registerEmail);

  const { toast } = useToast();
  const navigate = useNavigate();

  const { setError, ...form } = useForm({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
      firstname: "",
      surname: "",
      phone: "",
    },
  });
  useEffect(() => {
    if (registerEmail) {
      form.setValue("email", registerEmail);
    }
  }, [registerEmail, form]);

  async function onSubmit(data: z.infer<typeof LoginSchema>) {
    setLoading(true);
    try {
      const res = await setData({
        path: "/users/signup",
        formData: JSON.stringify({
          email: data.email,
          password: data.password,
          firstname: data.firstname,
          surname: data.surname,
          phone: data.phone,
        }),
        method: "POST",
      });
      setLoading(false);
      if (res && res.email) {
        toast({
          title: "Information captured",
        });
        navigate("/check-email");
      } else {
        setError("email", { type: "server", message: res.error });
      }
    } catch (e) {
      setError("email", { type: "server", message: e as string });
    }
  }

  return (
    <div className="container relative flex flex-col justify-center items-center mt-10">
      <h1 className="text-3xl mb-4">Register</h1>
      <Form {...form} setError={setError}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-full max-w-md space-y-3"
        >
          <FormField
            control={form.control}
            name="firstname"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="First Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="surname"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Surname" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <PhoneInput
                    inputProps={{
                      name: "",
                    }}
                    country={"za"}
                    {...field}
                    masks={{ za: "(..) ...-....", at: "(....) ...-...." }}
                    inputClass="phone-input"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            disabled={registerEmail ? true : false}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input type="password" placeholder="Password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-center">
            <Button className="w-full" type="submit" loading={useLoading}>
              Register
            </Button>
          </div>
        </form>
      </Form>
      <div className="mt-8 max-w-md w-full ">
        <Separator className="my-4" />
        <p className="text-center mb-4">Already have an account?</p>
        <Button onClick={() => navigate("/")} className="w-full" type="button">
          Login
        </Button>
        <Separator className="my-4" />
        <p className="text-center mb-4">Forgot your password?</p>
        <Button
          onClick={() => navigate("/forgot-password")}
          className="w-full"
          type="button"
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
}
