import "react-phone-input-2/lib/material.css";

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

export default function LoginPage() {
  return (
    <div className="container relative flex flex-col justify-center items-center mt-10">
      <h1 className="text-3xl mb-4">
        Please verify your account via your email
      </h1>
    </div>
  );
}
