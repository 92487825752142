import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useStore } from "@/lib/store";
import { UserNav } from "./UserNav";
import { NextLink } from "./ui/Link";
import logo from "./logolarge.png";

const TopNavigator: React.FC = () => {
  const token = useStore((state) => state.token);

  const location = useLocation();
  const pathname = location.pathname;
  const routePath = pathname.split("/")[1];
  const isPreview = pathname.split("/")[3] === "preview";
  const isView = pathname.split("/")[1] === "view";


  if (isPreview) {
    const id = pathname.split("/")[2];

    return (
      <NextLink
        href={`/workspace/${id}/design`}
        className="mt-5 ml-5 absolute z-10"
      >
        Return to Editor
      </NextLink>
    );
  }
  if (isView) {
    return null;
  }

  return (
    <div className="border-b-[1px] border-slate-400">
      <div className="relative container flex flex-1">
        <nav className="flex justify-items-start w-[100%] align-top">
          <div className="flex flex-row items-center space-x-4 w-full justify-start">
            <Link
              to={token ? "/workspace" : "/"}
              className="scroll-m-20 mr-3 text-lg font-extrabold tracking-tight transition-colors hover:text-primary"
            >
              <img src={logo} alt="Toolcrafter Logo" width={221} height={60} />
            </Link>
            { token !== null && (
              <>
                <Link
                  to="/workspace"
                  className={`text-sm pb-1 transition-colors hover:text-primary ${routePath === "workspace"
                      ? "font-extrabold border-b-[1px] border-black"
                      : ""
                    }`}
                >
                  Workspace
                </Link>
                {/* Uncomment and adjust as necessary
                <Link
                  to="/learn"
                  className={`text-sm pb-1 transition-colors hover:text-primary ${
                    routePath === "learn"
                      ? "font-extrabold border-b-[1px] border-black"
                      : ""
                  }`}
                >
                  Learn
                </Link>
                <Link
                  to="/resources"
                  className={`text-sm pb-1 transition-colors hover:text-primary ${
                    routePath === "resources"
                      ? "font-extrabold border-b-[1px] border-black"
                      : ""
                  }`}
                >
                  Resources
                </Link> */}
              </>
            )}
          </div>
          <UserNav />
        </nav>
      </div>
    </div>
  );
};

export default TopNavigator;
