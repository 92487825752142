import { useStore } from "./store";

export async function setData({
  path,
  formData,
  method = "PUT",
}: {
  path: string;
  formData?: any;
  method?: string;
}) {
  const url = `${
    process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1"
  }${path}`;
  const token = useStore.getState().token;
  let headers: any = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };
  }
  const response = await fetch(url, {
    body: formData,
    method,
    // mode: 'no-cors',
    headers,
  });
  if (!response.ok) {
    const res = await response.json();
    return { error: res.error, ...res };
  }
  const res = await response.json();
  if (res.data && Object.keys(res.data).length > 0) {
    return res.data;
  }
  return res;
}
