import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Home from "../app/page";
import ChangePassword from "../app/change-password/[userId]/[code]/page";
import CheckEmail from "../app/check-email/page";
import CollaboratorInvite from "../app/collaborator-invite/[userId]/[code]/page";
import ForgotPassword from "../app/forgot-password/page";
import Profile from "../app/profile/page";
import Register from "../app/register/page";
import VerifyEmail from "../app/verify-email/[userId]/[code]/page";
import WorkspaceAnalytics from "../app/workspace/[id]/analytics/page";
import WorkspaceCollaborators from "../app/workspace/[id]/collaborators/page";
import WorkspaceCustomDomain from "../app/workspace/[id]/customDomain/page";
import WorkspaceDesign from "../app/workspace/[id]/design/page";
import WorkspaceGeneral from "../app/workspace/[id]/general/page";
import WorkspaceLeads from "../app/workspace/[id]/leads/page";
import WorkspacePremium from "../app/workspace/[id]/premium/page";
import WorkspacePreview from "../app/workspace/[id]/preview/page";
import WorkspaceSource from "../app/workspace/[id]/source/page";
import WorkspaceWizard from "../app/workspace/[id]/wizard/page";
import Cells from "../app/workspace/[id]/cells/page";
import WorkspaceBilling from "../app/workspace/billing/page";
import WorkspaceCreate from "../app/workspace/create/page";
import WorkspaceMedia from "../app/workspace/media/[id]/page";
import WorkspaceMediaCreate from "../app/workspace/media/create/page";
import WorkspaceMediaIndex from "../app/workspace/media/page";
import Workspace from "../app/workspace/page";
import WorkspacePaymentRedirect from "../app/workspace/paymentredirect/page";
import WorkspacePlans from "../app/workspace/plans/page";
import LoginPage from "../app/page";
import DeveloperPage from "@/app/workspace/developer";

const DynamicWorkspaceComponent = ({ Component }: any) => {
  const { id } = useParams();
  return <Component params={{ id }} />;
};

const AppRoutes = () => {
  return (
    <Routes>
      {/* Routes without the layout */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/check-email" element={<CheckEmail />} />
      <Route path="/profile" element={<Profile />} />

      {/* Routes with the WorkspaceLayout */}
      <Route path="/" element={<Home />} />
      <Route
        path="/change-password/:userId/:code"
        element={<ChangePassword />}
      />
      <Route
        path="/collaborator-invite/:userId/:code"
        element={<CollaboratorInvite />}
      />
      <Route path="/verify-email/:userId/:code" element={<VerifyEmail />} />
      <Route path="/workspace" element={<Workspace />} />
      <Route path="/workspace/billing" element={<WorkspaceBilling />} />
      <Route path="/workspace/developer" element={<DeveloperPage />} />
      <Route
        path="/workspace/create"
        element={
          <WorkspaceCreate
            params={{
              id: "",
            }}
          />
        }
      />
      <Route path="/workspace/plans" element={<WorkspacePlans />} />
      <Route
        path="/workspace/paymentredirect"
        element={<WorkspacePaymentRedirect />}
      />
      <Route path="/workspace/media" element={<WorkspaceMediaIndex />} />
      <Route
        path="/workspace/media/create"
        element={<WorkspaceMediaCreate />}
      />
      <Route
        path="/workspace/media/:id"
        element={<DynamicWorkspaceComponent Component={WorkspaceMedia} />}
      />
      <Route
        path="/workspace/:id/analytics"
        element={<DynamicWorkspaceComponent Component={WorkspaceAnalytics} />}
      />
      <Route
        path="/workspace/:id/collaborators"
        element={
          <DynamicWorkspaceComponent Component={WorkspaceCollaborators} />
        }
      />
      <Route
        path="/workspace/:id/customDomain"
        element={
          <DynamicWorkspaceComponent Component={WorkspaceCustomDomain} />
        }
      />
      <Route
        path="/workspace/:id/design"
        element={<DynamicWorkspaceComponent Component={WorkspaceDesign} />}
      />
      <Route
        path="/workspace/:id/general"
        element={<DynamicWorkspaceComponent Component={WorkspaceGeneral} />}
      />
      <Route
        path="/workspace/:id/leads"
        element={<DynamicWorkspaceComponent Component={WorkspaceLeads} />}
      />
      <Route
        path="/workspace/:id/premium"
        element={<DynamicWorkspaceComponent Component={WorkspacePremium} />}
      />
      <Route
        path="/workspace/:id/preview"
        element={<DynamicWorkspaceComponent Component={WorkspacePreview} />}
      />
      <Route
        path="/workspace/:id/source"
        element={<DynamicWorkspaceComponent Component={WorkspaceSource} />}
      />
      <Route
        path="/workspace/:id/wizard"
        element={<DynamicWorkspaceComponent Component={WorkspaceWizard} />}
      />
      <Route
        path="/workspace/:id/cells"
        element={<DynamicWorkspaceComponent Component={Cells} />}
      />
    </Routes>
  );
};

export default AppRoutes;
