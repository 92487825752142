import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useStore } from "@/lib/store";
import { useNavigate } from "react-router-dom";
export default function IndexPage({ params }: { params: { id: string } }) {
  const id = params.id;
  const token = useStore((state) => state.token);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const { setValue, ...form } = useForm({});
  async function onSubmit(data: any) {
    try {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", token || "");
      const formdata = new FormData();
      formdata.append("name", data.name);
      let toolUrl = `${process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1"
        }/tools/name/${id}`;
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow" as RequestRedirect,
      };

      const response = await fetch(toolUrl, requestOptions);
      const res = await response.json();
      // toast({
      //   title: "Tool Updated",
      //   description: `You have updated ${res.data.name}`,
      // });
      setLoading(false);
      navigate(`/workspace/${id}/cells`);
    } catch (error) {
      console.log(error);
    }
  }

  const handleClose = (open: boolean) => {
    if (!open) {
      navigate(`/workspace/${id}/general`);
    }
  };

  return (
    <>
      <Dialog defaultOpen onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-xl mb-5">
              Give your tool a name
            </DialogTitle>
            <DialogDescription>
              <div className="flex flex-col">
                <Form {...form} setValue={setValue}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="w-full max-w-md space-y-3 flex flex-col"
                  >
                    <Input
                      {...form.register("name")}
                      placeholder="Tool name"
                      className="w-full p-2 border rounded"
                    />
                    <Button
                      type="submit"
                      loading={loading}
                      disabled={false}
                      className="mt-4 self-end"
                    >
                      Next Step
                    </Button>
                  </form>
                </Form>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
}
