import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import "react-phone-input-2/lib/material.css";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import * as z from "zod";
import { useToast } from "@/components/ui/use-toast";
import { setData } from "@/lib/setDataClient";
import { useState } from "react";
const LoginSchema = z.object({
  email: z.string().email(),
});

export default function LoginPage() {
  const [useLoading, setLoading] = useState(false);

  const { toast } = useToast();
  const navigate = useNavigate();

  const { setError, ...form } = useForm({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: "",
    },
  });
  async function onSubmit(data: z.infer<typeof LoginSchema>) {
    setLoading(true);
    try {
      const res = await setData({
        path: `/users/forgotpassword/${data.email}`,
        method: "GET",
      });
      setLoading(false);
      if (res && res.success) {
        toast({
          title: "Email submitted",
        });
        navigate("/check-email");
      } else {
        setError("email", { type: "server", message: res.error });
      }
    } catch (e) {
      setError("email", {
        type: "server",
        message: (e as string) || "An error occured",
      });
    }
  }

  return (
    <div className="container relative flex flex-col justify-center items-center mt-10">
      <h1 className="text-3xl mb-4">Forgot your Password?</h1>
      <Form {...form} setError={setError}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-full max-w-md space-y-3"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex justify-center">
            <Button className="w-full" type="submit" loading={useLoading}>
              Reset
            </Button>
          </div>
        </form>
      </Form>
      <div className="mt-8 max-w-md w-full ">
        <Separator className="my-4" />
        <p className="text-center mb-4">Already have an account?</p>
        <Button onClick={() => navigate("/")} className="w-full" type="button">
          Login
        </Button>
      </div>
    </div>
  );
}
