import { Section } from "../../../components/Section";
import { ImageSelect } from "../Image/ImageSelect";

export type HeaderLogoProps = {
  alt: string;
  width: number;
  imageOptions: any;
  images: any;
  height: number;
  imageUrl: string;
  align: "left" | "center" | "right";
};

export const HeaderLogo = ({ images }: any) => ({
  fields: {
    imageUrl: {
      type: "text",
    },
    imageOptions: {
      type: "custom",
      render: ({ name, onChange, value }: any) => {
        return (
          <ImageSelect images={images} onChange={onChange} value={value} />
        );
      },
    },
    alt: {
      type: "text",
    },
    width: {
      type: "number",
    },
    height: {
      type: "number",
    },
    align: {
      type: "radio",
      options: [
        { label: "left", value: "left" },
        { label: "center", value: "center" },
        { label: "right", value: "right" },
      ],
    },
  },
  defaultProps: {
    alt: "Logo",
    width: 100,
    height: 100,
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/en/thumb/5/52/Virgin_Money.svg/640px-Virgin_Money.svg.png",
    align: "center",
  },
  //@ts-ignore
  resolveData: async ({ props }, { changed }) => {
    // Remove read-only from the title field if `data` is empty
    if (!props.imageOptions) return { props, readOnly: { imageUrl: false } };

    // Don't query unless `data` has changed since resolveData was last run
    if (!changed.imageOptions) return { props };

    const width = props?.width;
    const height = props?.height;

    let imageUrl = `${
      process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1"
    }${props?.imageOptions.image}`;
    if (width && height) {
      imageUrl = imageUrl + `?dimensions=${props?.width}x${props?.height}`;
    }
    return {
      props: {
        width,
        height,
        imageUrl: `${
          process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1"
        }${props?.imageOptions.image}`,
        align: props?.align || "center", // Default to center if not provided
      },
    };
  },
  //@ts-ignore
  render: ({
    imageUrl,
    width,
    height,
    imageOptions,
    align,
  }: HeaderLogoProps) => {
    function isValidUrl(url: string) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    }

    const alignmentClasses: { [key in HeaderLogoProps["align"]]: string } = {
      left: "justify-start",
      center: "justify-center",
      right: "justify-end",
    };

    return (
      <Section>
        <div className={`flex w-full ${alignmentClasses[align]} pt-10 mb-6`}>
          {isValidUrl(imageUrl) ? (
            <img
              src={`${imageUrl || ""}`}
              crossOrigin="anonymous"
              alt={imageOptions?.alt || ""}
              width={width || 250}
              height={height || 250}
            ></img>
          ) : (
            <div>Image not found</div>
          )}
        </div>
      </Section>
    );
  },
});
