import { domToPng, domToJpeg, domToWebp } from "modern-screenshot";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ChevronRight } from "lucide-react"

import SkeletonPage from "@/components/SkeletonPage";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Puck } from "@measured/puck";
import "@measured/puck/puck.css";

import { useToast } from "@/components/ui/use-toast";
import { setData as setDataC } from "@/lib/setDataClient";
import { useStore } from "@/lib/store";
import { Link } from "lucide-react";
import { NextLink } from "@/components/ui/Link";

export default function PuckConfig({
  resolvedData,
  renderConfig,
  setData,
  puckData,
  customDomain,
  isPublished,
  id,
  toolName,
  form,
  register,
}: {
  register: any;
  form: any;
  puckData: any;
  resolvedData: any;
  id: any;
  customDomain?: any;
  isPublished?: boolean;
  toolName: any;
  setData: any;
  renderConfig: any;
}) {
  const [useResolved, setResolved] = useState(null);
  const [useLoadingPreview, setLoadingPreview] = useState(false);
  const [useLoadingSave, setLoadingSave] = useState(false);
  const [usePublished, setIsPublished] = useState(isPublished);
  const ref = useRef<HTMLDivElement>(null);
  const { toast } = useToast();
  const navigate = useNavigate();

  const postDataToAPI = async (newData: any, image: any) => {
    return await setDataC({
      path: `/tools/designer/${id || ""}`,
      formData: JSON.stringify({ data: { ...newData }, listingImage: image }),
      method: "POST",
    });
  };

  useEffect(() => {
    setIsPublished(isPublished);
  }, [isPublished]);

  const publish = async () => {
    setIsPublished(!usePublished);
    await setDataC({
      path: `/tools/publish/${id}/${usePublished ? "0" : "1"}`,
      method: "PUT",
    });
    toast({
      title: `Tool ${usePublished ? "Un Published" : "Published"}`,
    });
  };

  const getImage = async () => {
    try {
      if (ref.current) {
        const options = {
          quality: 0.5,
          debug: true,
          progress: (current: any, total: any) =>
            console.log(`Progress: ${current}/${total}`),
        };

        let image = await domToPng(ref.current, options);
        if (!image || image === "data:,") {
          console.error(
            "Failed to capture image using domToPng, trying domToJpeg"
          );
          image = await domToJpeg(ref.current, options);
        }
        if (!image || image === "data:,") {
          console.error(
            "Failed to capture image using domToJpeg, trying domToWebp"
          );
          image = await domToWebp(ref.current, options);
        }

        if (image && image !== "data:,") {
          postDataToAPI({ ...puckData }, image);
          useStore.setState({ puckData: puckData });
        }
      } else {
        console.error("ref.current is null");
      }
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  useEffect(() => {
    const runWait = async () => {
      const res = await resolvedData;
      if (!useResolved) {
        setResolved(res);
      }
    };
    runWait();
  }, [resolvedData, useResolved]);

  if (!useResolved) return <SkeletonPage />;

  return (
    <div className="border w-full">
      <div className="flex flex-col p-4 mb-2">
        <Button className="w-16" variant="default" onClick={() => navigate("/workspace")}>
        <ChevronRight className="h-4 w-4" />Back to Workspace
        </Button>
        <div className="flex flex-row justify-between items-center">
          <h1 className="text-lg font-bold">{toolName || ''}</h1>
          <div className="flex flex-row">
            <Button
              variant={"outline"}
              loading={useLoadingSave}
              onClick={async () => {
                setLoadingSave(true);
                await getImage();
                setLoadingSave(false);
              }}
            >
              Save
            </Button>
            <Button
              className="ml-3"
              loading={useLoadingPreview}
              onClick={async () => {
                setLoadingPreview(true);
                await getImage();
                setLoadingPreview(false);
                navigate(`/workspace/${id}/preview`);
              }}
            >
              View page
            </Button>
            <Button
              className="ml-3"
              onClick={async () => {
                await getImage();
                await publish();
              }}
            >
              {usePublished ? "Un Publish" : "Publish"}
            </Button>
            {usePublished && (
              <NextLink
                target="_blank"
                className="flex items-center justify-start bg-black text-white p-2 rounded-md ml-3"
                href={`${
                  customDomain
                    ? `https://${customDomain}`
                    : "http://toolcrafter.io/" + id
                }`}
              >
                View Live Site
              </NextLink>
            )}
          </div>
        </div>
      </div>
      <Form
        className="puck w-full space-y-3 border-slate-400"
        {...(form as any)}
        register={register as any}
      >
        <div className="puck w-full">
          <Puck
            iframe={{ enabled: false }}
            config={renderConfig}
            onPublish={() => {}}
            data={useResolved}
            onChange={(newData) => {
              setData(newData);
            }}
            plugins={[
              {
                overrides: {
                  componentItem: ({ children }) => <div>{children}</div>,
                },
              },
            ]}
            headerPath={"/"}
            overrides={{
              preview: ({ children }) => <div ref={ref}>{children}</div>,
              header: ({ actions, children }) => (
                <div id="head">{children}</div>
              ),
            }}
          />
        </div>
      </Form>
    </div>
  );
}
