import React, { useEffect } from "react";
import { getData } from "@/lib/fetchDataClient";
import { useStore } from "@/lib/store";
import StoreInitializer from "../../../../components/StoreInitilizer";
import { useParams } from "react-router-dom";
import { columns } from "./components/columns";
import { DataTable } from "./components/data-table";

const IndexPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchCollaborators = async () => {
      const collaborators = await getData(`/tools/collaborators/${id}`);
      useStore.setState({ collaborators });
    };

    fetchCollaborators();
  }, [id]);

  return (
    <div className="grid w-full items-center gap-1.5 mb-16">
      <StoreInitializer collaborators={[]} />
      <DataTable columns={columns} />
    </div>
  );
};

export default IndexPage;
