import React, { useEffect, useState } from "react";
import { Badge } from "./ui/badge";
import { getData } from "@/lib/fetchDataClient";
import { ICard } from "./CardSingle";
import Cookies from "js-cookie";

const UserTitle: React.FC = () => {
  const [summary, setSummary] = useState<ICard["summary"] | null>(null);
  const [userName, setUserName] = useState<string>("User");

  useEffect(() => {
    const fetchData = async () => {
      const cards = (await getData("/tools")) as ICard;
      setSummary(cards.summary);

      const userCookie = Cookies.get("user");
      if (userCookie) {
        const user = JSON.parse(userCookie);
        setUserName(user.firstname || "User");
      }
    };

    fetchData();
  }, []);

  if (!summary) {
    return null; // or a loading spinner
  }

  return (
    <div className="flex items-center justify-between">
      <h3 className="scroll-m-20 text-lg font-semibold tracking-tight mr-3">
        {userName}&apos;s Workspace
      </h3>
      <Badge>
        {summary.toolsUsed} /{" "}
        {summary.toolsAllowed > 100000 ? "Unlimited" : summary.toolsAllowed}{" "}
        tools used
      </Badge>
    </div>
  );
};

export default UserTitle;
