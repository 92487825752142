import React, { useEffect, useRef, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";

import initData from "@/config/initData.json";
import { setData as setDataC } from "@/lib/setDataClient";

import zones from "@/config/initZones.json";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import CellFields from "@/components/CellFields";
import { useToast } from "@/components/ui/use-toast";
import { ICell, useStore } from "@/lib/store";
import { setData } from "@/lib/setDataClient";
import useToolData from "@/lib/useTool";
import SkeletonPage from "@/components/SkeletonPage";
import { allowedDuplicateComponents } from "@/config";
import {
    INContent,
    INContentOutputs,
    inputFormSubmit,
    root,
    sliderProps,
    toCamelCase,
    vertical,
} from "@/lib/utils";
import { getData } from "@/lib/fetchDataClient";
import { ScrollArea } from "@radix-ui/react-scroll-area";

const InputFormSchema = z.object({
    inputs: z.array(
        z.object({
            type: z.string().min(1),
            sheet: z.string().optional(),
            cell: z.string().optional(),
            label: z.string().min(1).optional(),
            namedRange: z.string().optional(),
        }).refine((data) => {
            if (data.type === 'namedRange') {
                return !!data.namedRange && !!data.label;
            } else if (data.type === 'cell') {
                return !!data.sheet && !!data.cell && !!data.label;
            }
            return true;
        }, {
            message: "Invalid input for type",
            path: ["type"]
        })
    ),
});

const OutputFormSchema = z.object({
    outputs: z.array(
        z.object({
            type: z.string().min(1),
            sheet: z.string().optional(),
            cell: z.string().optional(),
            label: z.string().min(1).optional(),
            namedRange: z.string().optional(),
        }).refine((data) => {
            if (data.type === 'namedRange') {
                return !!data.namedRange && !!data.label;
            } else if (data.type === 'cell') {
                return !!data.sheet && !!data.cell && !!data.label;
            }
            return true;
        }, {
            message: "Invalid output for type",
            path: ["type"]
        })
    ),
});

export default function IndexPage({ params }: { params: { id: string } }) {

    const id = params.id;
    const tool = useToolData(id);
    const { toast } = useToast();
    const navigate = useNavigate();

    const [savedInputData, setSavedInputData] = useState([]);
    const [useCellType, setCellType] = useState<"inputs" | "outputs">("inputs");
    const [loading, setLoading] = useState<boolean>(false);

    const [useNamedRanges, setNamedRanges] = useState<Record<string, any>>({});
    const [useNamedRangeRefObject, setNamedRangeRefObject] = useState<Record<string, any>>({});

    useEffect(() => {
        const fetchData = async () => {
            const [namedRangesRes] = await Promise.all([
                getData(`/tools/named-ranges/${id}`)
            ]);

            if (tool) {
                if (namedRangesRes) {
                    setNamedRangeRefObject(namedRangesRes);

                    const { inputs = [], outputs = [] } = namedRangesRes;
                    setNamedRanges({
                        inputs: inputs.map((i: any) => i.name),
                        outputs: outputs.map((o: any) => o.name),
                    });
                }
            }
        };

        fetchData();
    }, [id, tool]);

    const inputForm = useForm({
        resolver: zodResolver(InputFormSchema),
        defaultValues: {
            inputs: [{ sheet: "", cell: "", label: "", namedRange: "", type: "" }],
        },
    });

    const { fields: inputFields, append: appendInput, remove: removeInput } = useFieldArray({
        control: inputForm.control,
        name: "inputs",
    });

    const outputForm = useForm({
        resolver: zodResolver(OutputFormSchema),
        defaultValues: {
            outputs: [{ sheet: "", cell: "", label: "", namedRange: "", type: "" }],
        },
    });

    const { fields: outputFields, append: appendOutput, remove: removeOutput } = useFieldArray({
        control: outputForm.control,
        name: "outputs",
    });

    const currentCellsRef = useRef([]);
    //@ts-ignore
    const usedLabels = new Set(currentCellsRef.current.map((item) => item.type));

    const filteredCells = (cells: ICell[] | undefined) =>
        cells?.filter(
            (cell) =>
                !usedLabels.has(cell.label) ||
                allowedDuplicateComponents.includes(cell.label)
        );

    const onInputsSubmit = async (data: any) => {

        const processedInputs = data.inputs.map((input: any) => {
            if (input.type === "namedRange" && input.namedRange) {
                //@ts-ignore
                const range = useNamedRangeRefObject.inputs?.find((nr: any) => nr.name === input.namedRange);
                console.log("🚀 ~ processedInputs ~ useNamedRangeRefObject:", useNamedRangeRefObject)
                if (range) {
                    return { ...input, cell: range.address, value: range.value, sheet: range.sheet };
                }
            }
            return input;
        });
        console.log("🚀 ~ processedInputs ~ data.inputs:", data.inputs)

        setSavedInputData(processedInputs);
        console.log("🚀 ~ onInputsSubmit ~ processedInputs:", processedInputs)
        setCellType("outputs");
    };

    const onOutputsSubmit = async (outputData: any) => {
        setLoading(true);

        const processedOutputs = outputData.outputs.map((output: any) => {
            if (output.type === "namedRange" && output.namedRange) {//@ts-ignore
                const range = useNamedRangeRefObject.outputs?.find((nr: any) => nr.name === output.namedRange);
                if (range) {
                    return { ...output, cell: range.address, value: range.value, sheet: range.sheet };
                }
            }
            return output;
        });

        const combinedData = {
            inputs: savedInputData,
            outputs: processedOutputs,
        };

        try {
            var formData = JSON.stringify(combinedData);
            const res = await setData({
                path: `/tools/${tool?.details[0]?.uuid || ""}`,
                formData,
                method: "POST",
            });

            toast({
                title: "Tool Cells Updated",
                description: "You have successfully updated the tool.",
            });

            const newContent: INContent = [];
            filteredCells(res.inputs)?.forEach((item, index, array) => {
                let prp = {
                    ...item,
                    inputOutput: "input",
                    id: `${toCamelCase(item.label)}-${Math.random()
                        .toString(36)
                        .substr(2, 9)}`,
                };
                if (item.dataType !== "List") {
                    let sliderPrpWithDefault = sliderProps;
                    if (item.defaultValue) {//@ts-ignore
                        sliderPrpWithDefault = { ...sliderProps, defaultValue: item.defaultValue };
                    }//@ts-ignore
                    prp = {
                        ...prp,
                        ...sliderPrpWithDefault,
                    };
                }
                newContent.push({
                    type: item.label,//@ts-ignore
                    props: prp,
                });
                if (index < array.length - 1) {
                    newContent.push({ ...vertical });
                }
            });

            const newContentOutputs: INContentOutputs = [];
            filteredCells(res.outputs)?.forEach((item, index, array) => {
                let sliderPrpWithDefault = sliderProps;
                if (item.defaultValue) {//@ts-ignore
                    sliderPrpWithDefault = { ...sliderProps, defaultValue: item.defaultValue };
                }
                newContentOutputs.push({
                    type: item.label,
                    props: {
                        id: `${toCamelCase(item.label)}-${Math.random()
                            .toString(36)
                            .substr(2, 9)}`,
                        ...sliderPrpWithDefault,
                    },
                });
                if (index < array.length - 1) {
                    newContentOutputs.push({ ...vertical });
                }
            });

            const newPuck = {
                root,
                zones,
                content: [
                    ...initData,
                    ...newContent,
                    vertical,
                    inputFormSubmit,
                    ...newContentOutputs,
                ],
            };

            const postDataToAPI = async (newData: any, image: any) => {
                return await setDataC({
                    path: `/tools/designer/${id || ""}`,
                    formData: JSON.stringify({
                        data: { ...newData },
                        listingImage: "",
                    }),
                    method: "POST",
                });
            };

            await postDataToAPI(newPuck, "");
            setTimeout(() => {
                navigate(`/workspace/${id}/design`);
            }, 500);
        } catch (error) {
            toast({
                title: "Error",
                description: "An error occurred while updating the tool.",
            });
        } finally {
            setLoading(false);
        }
    };

    if (!tool || (tool && !tool.details[0].sheets)) {
        return <SkeletonPage />;
    }

    return (
        <>
            <Dialog defaultOpen onOpenChange={() => { }}>
                <DialogContent className="md:max-w-xxl max-h-[80vh] overflow-hidden">
                    <DialogHeader>
                        <DialogTitle className="text-xl mb-5">
                            Configure your {useCellType}
                        </DialogTitle>
                    </DialogHeader>
                    <DialogDescription>
                        <div className="flex flex-col">
                            {tool ? (
                                <>
                                    {useCellType === "inputs" ? (
                                        <Form {...inputForm} setValue={inputForm.setValue}>
                                            <form
                                                onSubmit={inputForm.handleSubmit(onInputsSubmit)}
                                                className="flex flex-col w-full space-y-3"
                                            >
                                                <ScrollArea className="max-h-[60vh] overflow-y-auto">
                                                    <div className="w-full pr-2 mb-6 lg:mb-0">
                                                        <CellFields
                                                            hideTitle //@ts-ignore
                                                            fields={inputFields}
                                                            key={1}
                                                            namedRanges={useNamedRanges}
                                                            setValue={inputForm.setValue}
                                                            append={appendInput}
                                                            remove={removeInput} //@ts-ignore
                                                            control={inputForm.control}
                                                            namePrefix={"inputs"}
                                                            form={inputForm}
                                                            title={"Input Cells"}
                                                            tool={tool}
                                                        />
                                                    </div>
                                                </ScrollArea>
                                                <Button
                                                    type="submit"
                                                    loading={loading}
                                                    disabled={false}
                                                    className="mt-4 self-end"
                                                >
                                                    Next Step
                                                </Button>
                                            </form>
                                        </Form>
                                    ) : (
                                        <Form {...outputForm} setValue={outputForm.setValue}>
                                            <form
                                                onSubmit={outputForm.handleSubmit(onOutputsSubmit)}
                                                className="flex flex-col w-full max-w-md space-y-3"
                                            >
                                                <ScrollArea className="max-h-[60vh] overflow-y-auto">
                                                    <div className="w-full pr-2 mb-6 lg:mb-0">
                                                        <CellFields //@ts-ignore
                                                            fields={outputFields}
                                                            append={appendOutput}
                                                            remove={removeOutput}
                                                            setValue={outputForm.setValue}
                                                            key={2}

                                                            hideTitle //@ts-ignore
                                                            outputControl={outputForm.control}
                                                            namePrefix={"outputs"}
                                                            namedRanges={useNamedRanges}
                                                            title={"Output Cells"}
                                                            tool={tool}
                                                        />
                                                    </div>
                                                </ScrollArea>
                                                <Button
                                                    type="submit"
                                                    loading={loading}
                                                    disabled={false}
                                                    className="mt-4 self-end"
                                                >
                                                    Next Step
                                                </Button>
                                            </form>
                                        </Form>
                                    )}
                                </>
                            ) : (
                                <SkeletonPage />
                            )}
                        </div>
                    </DialogDescription>
                </DialogContent>
            </Dialog>
        </>
    );
}
