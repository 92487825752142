import * as z from "zod";

export const FormSchema = z
  .object({
    inputs: z.array(
      z.object({
        sheet: z.string().min(1),
        cell: z.string().refine((value) => /^[A-Z]+\d+$/.test(value), {
          message: "Incorrect format",
        }),
        label: z.string().min(1),
        namedRange:z.string(),
      type:z.string()
      })
    ),
    file: z.string().optional(),
    outputs: z.array(
      z.object({
        sheet: z.string().min(1),
        cell: z.string().refine((value) => /^[A-Z]+\d+$/.test(value), {
          message: "Incorrect format",
        }),
        namedRange:z.string(),
      type:z.string(),
        label: z.string().min(1),
      })
    ),
  })
  .refine(
    (data) => {
      const allLabels = [
        ...data.inputs.map((input) => input.label),
        ...data.outputs.map((output) => output.label),
      ];
      return new Set(allLabels).size === allLabels.length;
    },
    {
      message: "Labels must be unique across inputs and outputs",
      path: ["inputs", "outputs"],
    }
  );
