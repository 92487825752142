import { ICollaborator, useStore } from "@/lib/store";
import { useEffect } from "react";

function StoreInitializer({
  collaborators,
}: {
  collaborators: ICollaborator[];
}) {
  useEffect(() => {
    useStore.setState({ collaborators });
  }, [collaborators]);

  return null;
}

export default StoreInitializer;
