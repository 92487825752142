import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import SkeletonPage from "@/components/SkeletonPage";
import { setData } from "@/lib/setDataClient";
import { useStore } from "@/lib/store";

export default function IndexPage() {
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState<
    null | "success" | "error"
  >(null);
  const [searchParams] = useSearchParams();
  const redirectId = searchParams.get("id");
  let cid: any = undefined;
  if (typeof window !== "undefined") {
    cid = localStorage.getItem("cid");
  }
  const resourcePath = searchParams.get("resourcePath");

  useEffect(() => {
    const sub = async () => {
      try {
        const res = await setData({
          path: `/payment/initialPaymentStatus/${cid}/${redirectId}`,
          formData: JSON.stringify({
            resourcePath: resourcePath?.split("/v1/payments/")[1],
          }),
          method: "POST",
        });
        if (res.success) {
          const currentPlanTempCookie = Cookies.get("currentPlanTemp");
          const currentPlanTemp =
            currentPlanTempCookie && JSON.parse(currentPlanTempCookie);
          useStore.setState({
            currentPlan: currentPlanTemp,
          });
          setPaymentStatus("success");
        } else {
          setPaymentStatus("error");
        }
      } catch (error) {
        console.error(error);
        setPaymentStatus("error");
      } finally {
        setLoading(false);
      }
    };
    if (resourcePath && cid && redirectId) {
      sub();
    }
  }, [cid, redirectId, resourcePath]);

  if (loading) return <SkeletonPage />;
  if (paymentStatus === "success") {
    return (
      <>
        <h5 className="text-xl mt-6 font-bold tracking-tight text-black mb-2">
          Congratulations, your payment was successful
        </h5>
      </>
    );
  } else {
    return (
      <>
        <h5 className="text-xl mt-6 font-bold tracking-tight text-black mb-2">
          Sorry, your payment was unsuccessful
        </h5>
      </>
    );
  }
}
