import React from "react";
import { Link, useLocation } from "react-router-dom";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string;
    title: string;
    icon?: ReactNode;
  }[];
}

export function SidebarNav({ className, items, ...props }: SidebarNavProps) {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <nav
      className={cn("flex w-64 flex-col hidden md:flex pt-1", className)}
      {...props}
    >
      {items.map((item) => (
        <Link key={item.href} to={item.href}>
          <div
            className={cn(
              pathname === item.href
                ? "bg-slate-200 hover:bg-muted rounded cursor-pointer"
                : "hover:bg-transparent hover:underline cursor-pointer",
              "flex items-center justify-start mb-1 py-2 px-3 text-sm"
            )}
          >
            {item.icon && (
              <span className="flex items-center mr-2">{item.icon}</span>
            )}
            {item.title}
          </div>
        </Link>
      ))}
    </nav>
  );
}
