import { cn } from "@/lib/utils";

import { Iimage } from "../page";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useStore } from "@/lib/store";

interface AlbumArtworkProps extends React.HTMLAttributes<HTMLDivElement> {
  image: Iimage;
  isMedia: boolean;
}

export function ToolImage({
  image,
  className,
  isMedia,
  ...props
}: AlbumArtworkProps) {
  useEffect(() => {
    useStore.setState({ selectedImage: undefined });
  }, []);
  const navigate = useNavigate();

  return (
    <div
      className={className}
      {...props}
      onClick={() => {
        useStore.setState({
          selectedImage: {
            imageUrl: image.image,
            alt: image.alt,
            name: image.name,
          },
        });
        navigate(`/workspace/media/${image.id}`);
      }}
    >
      <div className="overflow-hidden rounded-md">
        <img
          src={`${process.env.REACT_APP_BASE_URL}${image.image}?dimensions=300x300`}
          alt={image.alt}
          crossOrigin="anonymous"
          width={300}
          // @ts-ignore
          height={300}
          className={cn("object-contain transition-all hover:scale-105")}
        />
        <p className="text-center">{image.image.split("/")[2]}</p>
      </div>
    </div>
  );
}
