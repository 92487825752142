import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { useStore } from "@/lib/store";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";

export default function IndexPage() {
  const { setValue, ...form } = useForm({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const token = useStore((state) => state.token);
  const [fileName, setFileName] = useState("");

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setFileName(file.name); // Set the file name to state
      setSelectedFile(file);
    }
  }, []);
  const clearFile = () => {
    setFileName("");
    setSelectedFile(null);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"] },
  });

  const dropzoneStyles = `border-2 ${
    isDragActive ? "border-blue-400 bg-blue-100" : "border-gray-500"
  } border-dashed rounded-md p-6 py-10 text-center transition-all`;

  async function onSubmit(data: any) {
    if (!selectedFile) return;

    setLoading(true);
    const myHeaders = new Headers();
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("alt", data.alt);
    myHeaders.append("Authorization", token || "");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow" as RequestRedirect,
    };
    let url = `${
      process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1"
    }/media`;

    const response = await fetch(url, requestOptions);
    const res = await response.json();
    toast({ title: "Image added" });
    setLoading(false);
    navigate("/workspace/media");
  }

  return (
    <div className="container relative">
      <div className="flex justify-between flex-col lg:flex-row mb-3 pb-8">
        {/* <GeneralTitle title="Media" /> */}
      </div>
      <Form {...form} setValue={setValue}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-full max-w-xl"
        >
          {fileName && (
            <div className="flex items-center space-x-2 mb-3">
              <span className="text-sm">{fileName}</span>
              <button
                type="button"
                onClick={clearFile}
                className="text-blue-500 hover:text-blue-600"
              >
                Clear
              </button>
            </div>
          )}

          <div {...getRootProps()} className={dropzoneStyles}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the image here ...</p>
            ) : (
              <p>
                Drag/drop an image here, or click to select an image (PNG/JPG)
              </p>
            )}
          </div>
          <Input
            {...form.register("alt")}
            placeholder="Alt Text"
            className="w-full p-2 mb-2 mt-2 border rounded"
          />
          <Button type="submit">Add image</Button>
        </form>
      </Form>
    </div>
  );
}
