import { PlusCircledIcon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";
import CardList from "../../components/CardList";
import { ICard } from "../../components/CardSingle";
import UserTitle from "../../components/UserTitle";
import { NextLink } from "../../components/ui/Link";
import { getData } from "../../lib/fetchDataClient";
import { useStore } from "../../lib/store";

const IndexPage: React.FC = () => {
  const [cards, setCards] = useState<ICard | null>(null);
  const [toolsLeft, setToolsLeft] = useState(0);

  useEffect(() => {
    const fetchCards = async () => {
      useStore.setState({ selectedTool: undefined, puckData: undefined });
      const fetchedCards = (await getData("/tools")) as ICard;
      setCards(fetchedCards);
      setToolsLeft(fetchedCards.summary.toolsLeft);
    };

    fetchCards();
  }, []);

  return (
    <div className="relative w-full">
      <div className="flex justify-between flex-col lg:flex-row mb-3 pb-3 border-b-[1px] border-slate-400 w-full">
        <UserTitle />
        <NextLink
          disabled={toolsLeft < 1}
          className="mt-4 lg:mt-0 lg:self-end"
          href="/workspace/create"
        >
          Create tool <PlusCircledIcon className="ml-3" />
        </NextLink>
      </div>
      <CardList />
    </div>
  );
};

export default IndexPage;
