import { CellComponents } from "@/components/CustomInputCell/CellComponents";
import SkeletonPage from "@/components/SkeletonPage";
import createConfig from "@/config"; // Import the new function
import { useCombinedToolData } from "@/lib/useToolDataHook";
import { zodResolver } from "@hookform/resolvers/zod";
import { resolveAllData } from "@measured/puck";
import "@measured/puck/puck.css";
import { FormProvider, useForm } from "react-hook-form";

import { Image } from "@/config/blocks/Image";
import { HeaderLogo } from "@/config/blocks/HeaderLogo";
import { getData } from "@/lib/fetchDataClient";
import { useEffect, useState } from "react";
import { z } from "zod";
import { FormSchema } from "../source/formSchema";
import { customInputFormConfig } from "./InputFormSubmit";
import PuckConfig from "./PuckConfig";
import { useFonts } from "@/components/FontsProvider"; // Import the hook

export default function IndexPage({ params }: { params: { id: string } }) {

  const id = params.id;
  const [imagesState, setImages] = useState(null);
  const fonts = useFonts();

  const {
    tool,
    puckData,
    availableInputCells,
    availableOutputCells,
    setData,
    initialLoading,
  } = useCombinedToolData(id, false);
  useEffect(() => {
    const runWait = async () => {
      const res = (await getData("/media")) as any;

      const images = res.images;
      if (images) {
        //@ts-ignore
        // const thumbs = images.map(({ thumbnail }) => `${process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1"}${thumbnail}`);

        setImages(images);
      }
    };
    runWait();
  }, []);

  const { register, handleSubmit, ...form } = useForm<
    z.infer<typeof FormSchema>
  >({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      inputs: [{ sheet: "", cell: "", label: "" }],
      outputs: [{ sheet: "", cell: "", label: "" }],
    },
  });




  if (
    !tool ||
    !puckData?.content ||
    !availableInputCells ||
    !availableOutputCells ||
    initialLoading ||
    imagesState === null
  )
    return <SkeletonPage />;

  const inputCellComponents = CellComponents({
    availableCells: availableInputCells,
  });

  const outputCellComponents = CellComponents({
    availableCells: availableOutputCells,
    isOutput: true,
  });

  const components = {
    ...createConfig(fonts).components,
    Image: Image({ images: imagesState }),
    HeaderLogo: HeaderLogo({ images: imagesState }),
    //@ts-ignore
    InputFormSubmit: customInputFormConfig({ loading: false }),
    // Footer: customFooterConfig({ isDeletable: false }),
    ...inputCellComponents,
    ...outputCellComponents,
  };

  const renderConfig = {
    ...createConfig(fonts),
    //@ts-ignore
    categories: {
      inputs: {
        components: availableInputCells.map((cell) => cell.label),
      },
      calculatorSubmitButton: {
        title: "Calculator Submit Button",
        components: ["InputFormSubmit"],
      },

      outputs: {
        components: availableOutputCells.map((cell) => cell.label),
      },
      ...createConfig(fonts).categories,
    },
    components,
  };

  const mergedCells = [...availableInputCells, ...availableOutputCells];
  const mergedCellsLabels = mergedCells.map((t) => t.label);
  const mergedRenderConfigLabels = Object.keys(renderConfig.components);

  const filteredContent = puckData.content.filter(
    (pd) =>
      ///@ts-ignore
      mergedCellsLabels.includes(pd.type) ||
      ///@ts-ignore
      mergedRenderConfigLabels.includes(pd.type)
  );
  const filteredPuckDataByTool = {
    ...puckData,
    ///@ts-ignore
    content: filteredContent,
  };
  const resolvedData =
    ///@ts-ignore

    filteredPuckDataByTool &&
    renderConfig &&
    ///@ts-ignore

    resolveAllData(filteredPuckDataByTool, renderConfig as any);

  return (
    //@ts-ignore
    <FormProvider {...form}>
      <PuckConfig
        id={id}
        puckData={puckData}
        customDomain={
          tool.details[0].customDomain
            ? `${tool.details[0].customDomain}`
            : null
        }
        register={register}
        toolName={tool.details[0].name}
        renderConfig={renderConfig}
        isPublished={tool?.details[0]?.published}
        resolvedData={resolvedData}
        form={form}
        setData={setData}
      />
    </FormProvider>
  );
}
