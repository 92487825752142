import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { CodeBlock, dracula } from "react-code-blocks";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import { getData } from "@/lib/fetchDataClient";
import { setData } from "@/lib/setDataClient";

export default function DesignerPage() {
  const { toast } = useToast();
  const [apiKey, setApiKey] = useState('');
  const [apiKeyGeneratedDate, setApiKeyGeneratedDate] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [toolUuid, setToolUuid] = useState("your-tool-uuid"); // Replace with actual tool UUID

  // Fetch existing API key on component mount
  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const response = await getData(`/users/developer`);
        if (response && response.apiKey) {
          setApiKeyGeneratedDate(response.apiKey); // data.apiKey contains the generation date
        }
      } catch (error) {
        console.error("Error fetching API key:", error);
      }
    };

    fetchApiKey();
  }, []);

  const handleGenerateKeys = async () => {
    setGenerateLoading(true);
    try {
      const response = await setData({
        path: `/users/developer/apiKey`,
        method: "PUT",
      });
      if (response && response.apiKey) {
        setApiKey(response.apiKey);
        setApiKeyGeneratedDate(response.apikeyGeneratedDate);
        setOpenDialog(true);
      } else {
        toast({
          title: "Error",
          description: "Failed to generate API key.",
        });
      }
    } catch (error) {
      console.error("Error generating API key:", error);
      toast({
        title: "Error",
        description: "Failed to generate API key.",
      });
    } finally {
      setGenerateLoading(false);
    }
  };

  const handleDeleteApiKey = async () => {
    setDeleteLoading(true);
    try {
      const response = await setData({
        path: `/users/developer/apiKey`,
        method: "DELETE",
      });
      if (response && response.success) {
        setApiKey('');
        setApiKeyGeneratedDate(null);
        toast({
          title: "Success",
          description: "API key deleted successfully.",
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to delete API key.",
        });
      }
    } catch (error) {
      console.error("Error deleting API key:", error);
      toast({
        title: "Error",
        description: "Failed to delete API key.",
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  // Code examples with correct language identifiers
  const codeExamples = [
    {
      language: "python",
      code: `
import requests

url = "https://app.toolcrafter.app/v1/developers/calculate/\${toolUuid}"
headers = {
    'x-api-key': '\${accessKey}',
    'Content-Type': 'application/json'
}
payload = {
    "inputs": [
        {"cell": "Master:M18", "value": 11}
    ]
}
response = requests.post(url, json=payload, headers=headers)
if response.status_code == 200:
    print(response.json()) # Successful response
else:
    print(f"Error: {response.json()}")
      `,
    },
    {
      language: "javascript", // Use 'javascript' for Node.js
      code: `
const axios = require('axios');

const url = "https://app.toolcrafter.app/v1/developers/calculate/\${toolUuid}";
const headers = {
    'x-api-key': '\${accessKey}',
    'Content-Type': 'application/json'
};
const payload = {
    inputs: [
        { cell: "Master:M18", value: 11 }
    ]
};

axios.post(url, payload, { headers })
    .then(response => {
        if (response.data.success) {
            console.log(response.data); // Successful response
        } else {
            console.error("Error:", response.data.error);
        }
    })
    .catch(error => console.error(error));
      `,
    },
    {
      language: "jsx", // Use 'jsx' for React.js
      code: `
import React, { useEffect } from 'react';
import axios from 'axios';

const ToolCrafterAPI = () => {
    useEffect(() => {
        const fetchData = async () => {
            const url = "https://app.toolcrafter.app/v1/developers/calculate/\${toolUuid}";
            const headers = {
                'x-api-key': '\${accessKey}',
                'Content-Type': 'application/json'
            };
            const payload = {
                inputs: [
                    { cell: "Master:M18", value: 11 }
                ]
            };
            try {
                const response = await axios.post(url, payload, { headers });
                if (response.data.success) {
                    console.log(response.data);
                } else {
                    console.error("Error:", response.data.error);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    return <div>Check console for API response</div>;
};

export default ToolCrafterAPI;
      `,
    },
    {
      language: "php",
      code: `
<?php
$url = 'https://app.toolcrafter.app/v1/developers/calculate/\${toolUuid}';
$headers = [
    'x-api-key: \${accessKey}',
    'Content-Type: application/json'
];
$payload = json_encode([
    'inputs' => [
        ['cell' => 'Master:M18', 'value' => 11]
    ]
]);
$ch = curl_init($url);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
curl_setopt($ch, CURLOPT_POST, true);
curl_setopt($ch, CURLOPT_POSTFIELDS, $payload);
$response = curl_exec($ch);
curl_close($ch);
$response_data = json_decode($response, true);
if ($response_data['success']) {
    print_r($response_data);
} else {
    echo "Error: " . $response_data['error'];
}
?>
      `,
    },
    {
      language: "go", // Use 'go' for Golang
      code: `
package main

import (
    "bytes"
    "encoding/json"
    "fmt"
    "net/http"
)

func main() {
    url := "https://app.toolcrafter.app/v1/developers/calculate/\${toolUuid}"
    payload := map[string]interface{}{
        "inputs": []map[string]interface{}{
            {
                "cell": "Master:M18",
                "value": 11,
            },
        },
    }
    jsonData, _ := json.Marshal(payload)
    req, _ := http.NewRequest("POST", url, bytes.NewBuffer(jsonData))
    req.Header.Set("x-api-key", "\${accessKey}")
    req.Header.Set("Content-Type", "application/json")
    client := &http.Client{}
    resp, _ := client.Do(req)
    defer resp.Body.Close()
    var result map[string]interface{}
    json.NewDecoder(resp.Body).Decode(&result)
    if result["success"].(bool) {
        fmt.Println(result) // Successful response
    } else {
        fmt.Println("Error:", result["error"])
    }
}
      `,
    },
    {
      language: "bash", // cURL commands are usually represented with 'bash' or 'shell'
      code: `
curl -X POST https://app.toolcrafter.app/v1/developers/calculate/\${toolUuid} \\
-H "x-api-key: \${accessKey}" \\
-H "Content-Type: application/json" \\
-d '{"inputs": [{"cell": "Master:M18", "value": 11}]}'
      `,
    },
  ];

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">API Key Management</h2>
      {apiKeyGeneratedDate ? (
        <div className={cn("p-4", "bg-gray-100", "rounded-md")}>
          <p className="text-sm text-gray-700">
            API key generated at: <strong>{apiKeyGeneratedDate}</strong>
          </p>
          <Button
            variant="destructive"
            onClick={handleDeleteApiKey}
            loading={deleteLoading}
            className="mt-4"
          >
            Delete API Key
          </Button>
        </div>
      ) : (
        <Button onClick={handleGenerateKeys} loading={generateLoading}>
          Generate API Key
        </Button>
      )}
      {/* Code examples */}
      <>
        <h3 className="text-xl font-semibold mt-6 mb-2">API Usage Examples</h3>
        {codeExamples.map((example, index) => {
          const codeWithValues = example.code
            .replace(/\${toolUuid}/g, toolUuid);

          return (
            <div key={index} className="mb-6">
              <h4 className="text-lg font-light mb-2">
                {example.language.toUpperCase()} Example
              </h4>
              <CodeBlock
                text={codeWithValues}
                language={example.language}
                theme={dracula}
                showLineNumbers={false}
              />
            </div>
          );
        })}
      </>
      {/* Dialog for API key generation */}
      <Dialog
        open={openDialog}
        onOpenChange={(open) => {
          setOpenDialog(open);
          if (!open) {
            setApiKey('');
          }
        }}
      >
        <DialogContent className="max-w-2xl">
          <DialogTitle>API Key Generated</DialogTitle>
          <DialogDescription>
            <p>
              Your API key has been generated successfully. Please copy it now as you won't be able to see it again.
            </p>
            <div className="mt-4 flex items-center space-x-2">
              <div className="flex-1 p-2 bg-gray-100 rounded-md">
                <code>{apiKey}</code>
              </div>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(apiKey);
                  toast({
                    title: "Copied",
                    description: "API key copied to clipboard.",
                  });
                }}
              >
                Copy
              </Button>
            </div>
          </DialogDescription>
          <DialogClose asChild>
            <Button
              onClick={() => {
                setApiKey('');
                setOpenDialog(false);
              }}
              className="mt-4"
            >
              Close
            </Button>
          </DialogClose>
        </DialogContent>
      </Dialog>
    </div>
  );
}