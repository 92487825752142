import SkeletonPage from "@/components/SkeletonPage";
import { Form } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import createConfig from "@/config"; // Import the new function
import { Render } from "@measured/puck";
import "@measured/puck/dist/index.css";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { resolveAllData } from "@measured/puck";
import { useEffect, useState } from "react";
import { CellComponents } from "@/components/CustomInputCell/CellComponents";
import { customInputFormConfig } from "../design/InputFormSubmit";
import { useCombinedToolData } from "@/lib/useToolDataHook";
import { setData } from "@/lib/setDataClient";
import { Helmet } from "react-helmet";
import { convertToIcoAndSetFavicon } from "@/lib/utils";
import { useFonts } from "@/components/FontsProvider"; // Import the hook
import PuckConfig from "./PuckConfig";

const PreviewFormSchema = z.object({
  inputs: z.array(
    z.object({
      id: z.string(),
      value: z.string(),
    })
  ),
});

export default function PreviewPage({ params }: { params: { id: string } }) {
  const id = params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const { tool, puckData, availableInputCells, availableOutputCells } =
    useCombinedToolData(id);

  const {
    handleSubmit,
    formState: { errors },
    register,
    ...form
  } = useForm<z.infer<typeof PreviewFormSchema>>({});

  useEffect(() => {
    if (tool && tool?.details[0].favicon) {
      convertToIcoAndSetFavicon(tool?.details[0].favicon);
    }
  }, [tool]);

  const fonts = useFonts(); // Fetch fonts using the custom hook

  const { toast } = useToast();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      console.log(errors);
    }
  }, [errors]);

  useEffect(() => {
    if (tool && availableInputCells && availableOutputCells && puckData) {
      setIsDataLoaded(true);
    }
  }, [tool, availableInputCells, availableOutputCells, puckData]);

  const onSubmit = handleSubmit(async (formData) => {
    setIsLoading(true);
    try {
      const inputCellIds = new Set(
        availableInputCells?.map((cell) => cell.id.toString())
      );

      const dataArray = Object.entries(formData)
        .filter(([key]) => inputCellIds.has(key))
        .map(([key, value]) => ({
          id: key,
          value: value,
        }));

      const res = await setData({
        path: `/tools/preview/${tool?.details[0]?.uuid || ""}`,
        formData: JSON.stringify({ inputs: dataArray }),
        method: "POST",
      });

      const outputs = res.outputs.reduce(
        (acc: { [key: string]: any }, output: any) => {
          //@ts-ignore
          form.setValue(`${output.id}`, `${output.value}`);
          acc[output.label] = output.value;
          return acc;
        },
        {}
      );

      const hasEmptyOutput = Object.values(outputs).some(
        (value) => value === ""
      );

      if (hasEmptyOutput) {
        toast({
          title: "Error occurred",
          description: "One or more output values are empty.",
          variant: "destructive",
        });
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
    } catch (e) {
      toast({
        title: "Error occurred",
        variant: "destructive",
      });
      setIsLoading(false);
    }
  });

  useEffect(() => {
    if (isDataLoaded) {
      onSubmit();
    }
  }, [isDataLoaded]);

  if (!tool || !availableInputCells || !availableOutputCells || !puckData)
    return <SkeletonPage />;

  const inputCellComponents = CellComponents({
    availableCells: availableInputCells,
    isLoading,
  });

  const outputCellComponents = CellComponents({
    availableCells: availableOutputCells,
    isOutput: true,
  });

  const components = {
    ...createConfig(fonts).components,
    //@ts-ignore
    InputFormSubmit: customInputFormConfig({ loading: false }),
    ...inputCellComponents,
    ...outputCellComponents,
  };

  const renderConfig = {
    ...createConfig(fonts),
    //@ts-ignore
    categories: {
      inputs: {
        components: availableInputCells.map((cell) => cell.label),
      },
      outputs: {
        components: availableOutputCells.map((cell) => cell.label),
      },
      ...createConfig(fonts).categories,
    },
    components,
  };

  const resolvedData =
    puckData && renderConfig && resolveAllData(puckData, renderConfig as any);

  return (
    //@ts-ignore
    <FormProvider>
      <Helmet>
        <link className="FormHead" rel="icon" href={tool?.details[0].favicon} />
      </Helmet>
      <PuckConfig
        onSubmit={onSubmit}
        isLoading={isLoading}
        id={id}
        register={register}
        renderConfig={renderConfig}
        resolvedData={resolvedData}
        form={form}
      />
    </FormProvider>
  );
}
