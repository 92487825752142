import { PageHeader } from "@/components/page-header";

export default function IndexPage({ params }: { params: { id: string } }) {
  return (
    <div className="container relative">
      <PageHeader className="pb-8">
        <div className="flex flex-row mb-3">
          <h3 className="scroll-m-20 text-3xl font-semibold tracking-tight mr-3">
            Leads: {params.id}
          </h3>
        </div>
      </PageHeader>
    </div>
  );
}
