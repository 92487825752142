import React from "react";
import { ComponentConfig } from "@measured/puck";
import { DropZone } from "@measured/puck";
import { Section } from "../../../components/Section";

export type CaclulatorBlockProps = {
  align: "left" | "center" | "right";
  backgroundColor: string;
  borderRadius: string;
};

export const CaclulatorBlock: ComponentConfig<CaclulatorBlockProps> = {
  fields: {
    align: {
      type: "radio",
      options: [
        { label: "left", value: "left" },
        { label: "center", value: "center" },
        { label: "right", value: "right" },
      ],
    },
    backgroundColor: {
      type: "text", // Using text type for backgroundColor
    },
    borderRadius: {
      type: "text", // Using text type for borderRadius
    },
  },
  defaultProps: {
    align: "left",
    backgroundColor: "white", // Default white background
    borderRadius: "none", // Default no border radius
  },
  render: ({ align, backgroundColor, borderRadius }) => {
    const alignmentClasses = {
      left: "justify-start",
      center: "justify-center",
      right: "justify-end",
    };
    return (
      <Section maxWidth="1280px">
        <div className={`flex w-[100%]  ${alignmentClasses[align]}`}>
          <div
            className={`w-[24em] mb-10 p-8 flex bg-red-500 flex-col `}
            style={{
              backgroundColor,
              borderRadius,
            }}
          >
            <div className="calculator-block" key={"idx"}>
              <DropZone zone={`column`} />
            </div>
          </div>
        </div>
      </Section>
    );
  },
};
