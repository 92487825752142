import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { CodeBlock, dracula } from "react-code-blocks";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "@/components/ui/dialog";
import { format } from "date-fns";
import { setData } from "@/lib/setDataClient"; // Imported setData

export default function DesignerPage() {
  const { toast } = useToast();
  const [accessKey, setAccessKey] = useState("");
  const [tokenGeneratedDate, setTokenGeneratedDate] = useState<string | null>(
    null
  );
  const [openDialog, setOpenDialog] = useState(false);

  const [toolUuid, setToolUuid] = useState("your-tool-uuid"); // Replace with actual tool UUID

  // Fetch existing API key on component mount
  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const response = await setData({
          path: `/users/developer`,
          method: "GET",
        });
        if (response.success) {
          setAccessKey(response.data.apiKey);
          setTokenGeneratedDate(response.datetime);
        }
      } catch (error) {
        console.error("Error fetching API key:", error);
      }
    };

    fetchApiKey();
  }, []);

  const handleGenerateKeys = async () => {
    try {
      const response = await setData({
        path: `/users/developer/apiKey`,
        method: "PUT",
      });
      if (response.success) {
        setAccessKey(response.data.apiKey);
        setTokenGeneratedDate(response.data.apikeyGeneratedDate);
        setOpenDialog(true);
      }
    } catch (error) {
      console.error("Error generating API key:", error);
      toast({
        title: "Error",
        description: "Failed to generate API key.",
      });
    }
  };

  const handleDeleteApiKey = async () => {
    try {
      const response = await setData({
        path: `/users/developer/apiKey`,
        method: "DELETE",
      });
      if (response.success) {
        setAccessKey("");
        setTokenGeneratedDate(null);
        toast({
          title: "Success",
          description: "API key deleted successfully.",
        });
      }
    } catch (error) {
      console.error("Error deleting API key:", error);
      toast({
        title: "Error",
        description: "Failed to delete API key.",
      });
    }
  };

  // Code examples
  const codeExamples = [
    {
      language: "Python",
      code: `
import requests

url = "https://app.toolcrafter.app/v1/developers/calculate/${toolUuid}"
headers = {
    'x-api-key': '${accessKey}',
    'Content-Type': 'application/json'
}
payload = {
    "inputs": [
        {"cell": "Master:M18", "value": 11}
    ]
}
response = requests.post(url, json=payload, headers=headers)
if response.status_code == 200:
    print(response.json()) # Successful response
else:
    print(f"Error: {response.json()}")
      `,
    },
    {
      language: "Node.js",
      code: `
const axios = require('axios');

const url = "https://app.toolcrafter.app/v1/developers/calculate/${toolUuid}";
const headers = {
    'x-api-key': '${accessKey}',
    'Content-Type': 'application/json'
};
const payload = {
    inputs: [
        { cell: "Master:M18", value: 11 }
    ]
};

axios.post(url, payload, { headers })
    .then(response => {
        if (response.data.success) {
            console.log(response.data); // Successful response
        } else {
            console.error("Error:", response.data.error);
        }
    })
    .catch(error => console.error(error));
      `,
    },
    {
      language: "React.js",
      code: `
import React, { useEffect } from 'react';
import axios from 'axios';

const ToolCrafterAPI = () => {
    useEffect(() => {
        const fetchData = async () => {
            const url = "https://app.toolcrafter.app/v1/developers/calculate/${toolUuid}";
            const headers = {
                'x-api-key': '${accessKey}',
                'Content-Type': 'application/json'
            };
            const payload = {
                inputs: [
                    { cell: "Master:M18", value: 11 }
                ]
            };
            try {
                const response = await axios.post(url, payload, { headers });
                if (response.data.success) {
                    console.log(response.data);
                } else {
                    console.error("Error:", response.data.error);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    return <div>Check console for API response</div>;
};

export default ToolCrafterAPI;
      `,
    },
    {
      language: "PHP",
      code: `
<?php
$url = 'https://app.toolcrafter.app/v1/developers/calculate/${toolUuid}';
$headers = [
    'x-api-key: ${accessKey}',
    'Content-Type: application/json'
];
$payload = json_encode([
    'inputs' => [
        ['cell' => 'Master:M18', 'value' => 11]
    ]
]);
$ch = curl_init($url);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
curl_setopt($ch, CURLOPT_POST, true);
curl_setopt($ch, CURLOPT_POSTFIELDS, $payload);
$response = curl_exec($ch);
curl_close($ch);
$response_data = json_decode($response, true);
if ($response_data['success']) {
    print_r($response_data);
} else {
    echo "Error: " . $response_data['error'];
}
?>
      `,
    },
    {
      language: "Golang",
      code: `
package main

import (
    "bytes"
    "encoding/json"
    "fmt"
    "net/http"
)

func main() {
    url := "https://app.toolcrafter.app/v1/developers/calculate/${toolUuid}"
    payload := map[string]interface{}{
        "inputs": []map[string]interface{}{
            {
                "cell": "Master:M18",
                "value": 11,
            },
        },
    }
    jsonData, _ := json.Marshal(payload)
    req, _ := http.NewRequest("POST", url, bytes.NewBuffer(jsonData))
    req.Header.Set("x-api-key", "${accessKey}")
    req.Header.Set("Content-Type", "application/json")
    client := &http.Client{}
    resp, _ := client.Do(req)
    defer resp.Body.Close()
    var result map[string]interface{}
    json.NewDecoder(resp.Body).Decode(&result)
    if result["success"].(bool) {
        fmt.Println(result) // Successful response
    } else {
        fmt.Println("Error:", result["error"])
    }
}
      `,
    },
    {
      language: "cURL",
      code: `
curl -X POST https://app.toolcrafter.app/v1/developers/calculate/${toolUuid} \\
-H "x-api-key: ${accessKey}" \\
-H "Content-Type: application/json" \\
-d '{"inputs": [{"cell": "Master:M18", "value": 11}]}'
      `,
    },
  ];

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">API Key Management</h2>
      <div className="flex items-center space-x-2">
        <Button onClick={handleGenerateKeys}>Generate API Key</Button>
        {accessKey && (
          <Button variant="outline" onClick={handleDeleteApiKey}>
            Delete API Key
          </Button>
        )}
      </div>
      {tokenGeneratedDate && (
        <p className="mt-2 text-gray-600">
          API key generated at: {tokenGeneratedDate}
        </p>
      )}
      <div className="mt-4">
        <Input
          readOnly
          value={accessKey}
          placeholder="API Key"
          className="mb-2"
        />
      </div>

      <h3 className="text-xl font-semibold mt-6 mb-2">API Usage Examples</h3>
      {codeExamples.map((example, index) => (
        <div key={index} className="mb-6">
          <h4 className="text-lg font-semibold mb-2">{example.language} Example</h4>
          <CodeBlock
            text={example.code}
            language={
              example.language === "cURL"
                ? "bash"
                : example.language.toLowerCase()
            }
            theme={dracula}
            showLineNumbers={false}
          />
        </div>
      ))}

      {/* Dialog for token generation */}
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogTrigger asChild>
          <Button className="hidden">Trigger Dialog</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogTitle>API Key Generated</DialogTitle>
          <DialogDescription>
            Your API key has been generated successfully. Copy the following
            key:
            <Input readOnly value={accessKey} className="mt-2" />
          </DialogDescription>
          <DialogClose asChild>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </DialogClose>
        </DialogContent>
      </Dialog>
    </div>
  );
}