import { jwtDecode } from "jwt-decode";

export const isTokenExpired = (token: string): boolean => {
    try {
        const decoded = jwtDecode(token) 
        if (!decoded.exp) return true;

        const now = Date.now().valueOf() / 1000;
        return now > decoded.exp;
    } catch (error) {
        return true;
    }
};