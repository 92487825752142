import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Row } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { getData } from "@/lib/fetchDataClient";
import { setData } from "@/lib/setDataClient";
import { useStore } from "@/lib/store";
import { useLocation, useNavigate } from "react-router-dom";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DataTableRowActions<TData>({
  row,
}: DataTableRowActionsProps<TData>) {
  const collaborators = useStore((state) => state.collaborators);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const id = pathname.split("/")[2];

  const handleDelink = async () => {
    await setData({
      //@ts-ignore
      path: `/tools/collaborators/${row.original.collaboratorId}`,
      method: "DELETE",
    });

    useStore.setState({
      collaborators: collaborators.filter(
        (
          collaborator //@ts-ignore
        ) => collaborator.collaboratorId !== row.original.collaboratorId
      ),
    });
  };

  const transferOwnership = async () => {
    //@ts-ignore
    await getData(`/tools/makeowner/${id}/${row.original.userId}`);
    navigate(`/workspace`);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuItem onClick={handleDelink}>
          Delink user from tool
        </DropdownMenuItem>
        <DropdownMenuItem onClick={transferOwnership}>
          Transfer ownership
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
