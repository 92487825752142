export const siteConfig = {
  name: "toolcrafter",
  url: process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app",
  ogImage: process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/",
  description:
    "Toolcrafter transforms spreadsheets into interactive web tools for easy online sharing",
  links: {
    twitter: "https://twitter.com/toolcrafter",
    github: "https://github.com/toolcrafter",
  },
};

export type SiteConfig = typeof siteConfig;
