import React, { createContext, useContext, useState, useEffect } from "react";

export type FieldOption = {
  label: string;
  value: string;
};

const FontsContext = createContext<FieldOption[]>([]);

const fetchGoogleFonts = async (): Promise<FieldOption[]> => {
  const response = await fetch(
    "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyC2IILD66z0e2fth_c8hLZBlvgjc-4vFLU"
  );
  const data = await response.json();
  return data.items.map((font: any) => ({
    label: font.family,
    value: font.family,
  }));
};

const useGoogleFonts = () => {
  const [fonts, setFonts] = useState<FieldOption[]>([]);

  useEffect(() => {
    const loadFonts = async () => {
      const loadedFonts = await fetchGoogleFonts();
      setFonts(loadedFonts);
    };
    loadFonts();
  }, []);

  return fonts;
};

export const FontsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const fonts = useGoogleFonts();
  return (
    <FontsContext.Provider value={fonts}>{children}</FontsContext.Provider>
  );
};

export const useFonts = () => useContext(FontsContext);
