import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}
export function ImageSelect({
  images,
  onChange,
  value,
}: {
  images: { id: number; image: string; thumbnail: string; alt: string }[];
  onChange: (imageObj: {
    id: number;
    image: string;
    thumbnail: string;
    alt: string;
  }) => void;
  value: any;
}) {
  const [selectedImage, setSelectedImage] = useState<{
    id: number;
    image: string;
    thumbnail: string;
    alt: string;
  } | null>(value || null);

  const [error, setError] = useState<string | null>(null);

  const handleImageSelect = (imageObj: {
    id: number;
    image: string;
    thumbnail: string;
    alt: string;
  }) => {
    const imageUrl = `${
      process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1"
    }${imageObj.thumbnail}`;
    if (isValidUrl(imageUrl)) {
      setSelectedImage(imageObj);
      setError(null);
    } else {
      setError("Invalid image URL");
    }
  };

  const handleConfirm = () => {
    if (selectedImage) {
      onChange(selectedImage);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Select an Image</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Select an image from your media</DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-6 mt-5">
          {images.map((imageObj) => {
            const imageUrl = `${
              process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1"
            }${imageObj.thumbnail}`;
            return (
              <div
                key={imageObj.id}
                className={`cursor-pointer overflow-hidden p-2 w-16 ${
                  selectedImage?.id === imageObj.id
                    ? "w-1 ring-1 ring-black rounded "
                    : ""
                }`}
                onClick={() => handleImageSelect(imageObj)}
              >
                <img
                  src={imageUrl}
                  width={75}
                  height={75}
                  //@ts-ignore
                  crossorigin="anonymous"
                  alt={imageObj.alt}
                  className="object-cover"
                />
              </div>
            );
          })}
        </div>

        <DialogFooter className="sm:justify-end mt-5">
          {selectedImage && (
            <DialogClose asChild>
              <Button type="button" onClick={handleConfirm}>
                Confirm
              </Button>
            </DialogClose>
          )}
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
